import React from "react";
import { FormLabel as MuiFormLabel, styled } from "@mui/material";

const FormLabel = styled(MuiFormLabel)({
    color: "#747474",
    fontSize: 16,
    fontFamily: [
        "Barmeno",
        "sans-serif"
    ].join(','),

    "&[data-required='true']::after": {
        color: "rgb(225, 27, 34)",
        content: "' *'",
        fontSize: 16,
    },

    "&.Mui-Focused": {
        color: "rgb(225, 27, 34)"
    }
});

export default FormLabel;
