import React from "react";

// Customizable Area Start
import "./CatalogueStyles.css";
import CustomLoader from "../../../components/src/CustomLoader.web";
import { Accordion, AccordionSummary, Button, FormControlLabel, IconButton, Radio, RadioGroup } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreRounded'
import CustomRadioBox from "../../../components/src/CustomRadioBox.web";
import { SidesTypes, SpiceLevelTypes } from "../../ordermanagement/src/types";
import CustomGreenCheckbox from "../../../components/src/CustomGreenCheckBox.web";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
// Customizable Area End

import CfposPrductDescriptionController, {
  DealProduct,
  Props
} from "./CfposPrductDescriptionController";
import {CustomizationData } from "./CfposordermanagementfeController";
import CustomPosRadioBox from "../../../components/src/CustomPosRadioBox";

export default class CfposPrductDescription extends CfposPrductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  accordianContainer = (spiceLevelList: SpiceLevelTypes[]) => {
    return (
      <Accordion
        expanded={this.state.expanded == `spiceLevel`}
        onChange={this.handleExpand(`spiceLevel`)}
        className="accordianPosProductDescription"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordian_spicelevel_summar"
          id="accordianPosProductDescriptionSummary"
        >
          Choose your spice level...
        </AccordionSummary>
        <RadioGroup
          row
          style={{ display: 'flex', flexDirection: 'row', gap: '16px', paddingTop: "12px" }}
          defaultValue={this.state.spiceLevel}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={this.setSpiceLevel}
        >
          {spiceLevelList.map((value: SpiceLevelTypes) =>
            <FormControlLabel key={value.id}
              value={value.attributes.name}

              control={<Radio style={{ display: "none" }} />}
              label={<div className={this.isSelectedSpiceLevel(value.attributes.name) ? "spiceLevelLabelValuePDSelected" : "spiceLevelLabelValuePD"}><div className="spiceImageContainer" style={{ backgroundImage: `url(${value.attributes.images.url})` }} ></div>
                <div>{value.attributes.name}</div>
              </div>} />

          )}
        </RadioGroup>
      </Accordion>
    )
  }


  accordianChooseYourType = (typeData: { two_reg_sides_price: number | null, on_its_own_price: number | null }) => {
    const { sidesDetails, chooseYourType } = this.state
    return (
      <Accordion
          expanded={this.state.expanded == `chooseYourTypeAccordian`}
          onChange={this.handleExpand(`chooseYourTypeAccordian`)}
        className="accordianPosProductDescription">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordian_spicelevel_summar"
          id="accordianPosProductDescriptionSummary"
        >
          Choose your type...
        </AccordionSummary>

        <RadioGroup
          id="changeChooseYourType"
          row
          value={chooseYourType}
          style={{ gap: "32px", paddingTop: "12px" }}
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          onChange={this.changeChooseYourType} 
         
        >
          <FormControlLabel className='form_control_label_choose_yourType'
            name={`${typeData.on_its_own_price}`}
            value={'on its own'}
            control={<CustomRadioBox/>}
            label={<div className='spiceLevel_label'><div><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >On its own</div>
              <div style={{ fontSize: "14px", color: "#747474", fontWeight: 700 }} >£{typeData.on_its_own_price}</div></div>
            </div>} />
          <FormControlLabel className='form_control_label_choose_yourType'
            name={`${typeData.two_reg_sides_price}`}
            value={'with 2 reg sides'}
            control={<CustomRadioBox/>}
            label={<div className='spiceLevel_label'><div><div style={{ fontSize: "18px", color: "#1D1D1D", fontWeight: 700 }} >With 2 reg sides</div>
              <div style={{ fontSize: "14px", color: "#747474", fontWeight: 700 }} >£{typeData.two_reg_sides_price}</div></div>
            </div>} />
        </RadioGroup>

        {chooseYourType === 'with 2 reg sides' && sidesDetails.length > 0 &&

          this.sidesDetailsContainer(sidesDetails)
        }
      </Accordion>
    )
  }

  sidesDetailsContainer = (sidesDetails: SidesTypes[]) => {
    return (
      <div className="sideContainerRegSide">
        <div className="categoryHeaderContainer" id="categoryHeaderContainer0"> Sides </div>
        <RadioGroup
          data-testId="sides"
          className="categoryItemContainer"
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >

          {sidesDetails.map((value: SidesTypes) =>
            <FormControlLabel key={value.id} className='form_control_label_spicelevel'
              data-testId="spiceLevel"
              value={value.id}
              onChange={(e) => { this.handleSideChange(value.id) }}
              control={
                <div>
                  <CustomGreenCheckbox style={{ position: "absolute", zIndex: 2, marginLeft: "3px" }}
                    checked={this.state.selectedSides.includes(value.id)}
                    disabled={!this.state.selectedSides.includes(value.id) && this.state.selectedSides?.length >= 2}
                  />
                  {this.itemContainer(value)}
                </div>
              }
              label={""} />
          )}

        </RadioGroup>
      </div>
    )
  }

  itemContainer = (data: SidesTypes) => {
    return (
      <div className="itemInfoContainer productDescription" tabIndex={0}>
        <div className="itemImageContainer" style={{ backgroundImage: `url(${data.attributes.images.url})` }} ></div>
        <div className="itemInfo">
          <div className="itemInfoTitle">{data.attributes.sub_sub_category_name}
          </div>
          <div className="itemInfoDescriptionPrice">£{data.attributes.on_its_own_price}</div>
        </div>
      </div>
    )
  }

  productDescriptioHeader = (values: { title: string, on_its_own_price: number, reg_sides_price: number | null, hasMains: boolean, hasDeals: boolean }) => {
    return (
      <div className="productDescriptionHeader" >
        <div className="productDescriptionTitleContainer" >
          <div className="productDescriptionTitle">{values.title}</div>
          {!values.hasDeals && <div className="productDescriptionTitle">{values.hasMains && "From"}(£{this.state.chooseYourType === "on its own" ? values.on_its_own_price : values.reg_sides_price})</div>}
        </div>
        <div className="splitContainer">
          {this.getSplitItems.map((value: string, index: number) =>
            <div className="splitContainerItems" key={`${value}${index}`}>{value}</div>
          )}
        </div>

      </div>
    )
  }

  notesAccordian = () => {
    const { productChefNotes, productChefNotesWordCount, productChefNotesWordCountError } = this.state
    return (
      <Accordion
        expanded={this.state.expanded == `notesPanel`}
        onChange={this.handleExpand(`notesPanel`)}
        className="accordianPosProductDescription"
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="accordian_spicelevel_summar"
          id="accordianPosProductDescriptionSummary"
        >
          Add Notes...
        </AccordionSummary>
        <div className='exclude_allergen_container' style={{ paddingTop: "12px" }} >
          <textarea
            style={{ borderColor: productChefNotesWordCountError ? "#E11B22" : "#BFBFBF" }}
            className='notesAccordainTextArea'
            placeholder='Add notes to the chef'
            onChange={this.handleChangeProductChefNotes}
            value={productChefNotes}
          />
          <div className='wordCount_excludeAllergen_container'>
            <div className='wordCount_excludeAllergen'>{productChefNotesWordCount}/50</div>
            <div className='wordCount_excludeAllergen_error'>{productChefNotesWordCountError}</div>
          </div>

        </div>
      </Accordion>
    )
  }

  dealableProducts = (deal_products: DealProduct[]) => {
    const validProducts = deal_products.filter(
      ({ attributes: { dealable_type } }) =>
        dealable_type !== "BxBlockCatalogue::FoodCategory" &&
        dealable_type !== "BxBlockCatalogue::SubSubCategory"
    );

    return validProducts.flatMap(({ attributes: { quantity, dealable: { id, title } } }) => {
      const itemsArray = Array.from({ length: quantity });
      return itemsArray.map((_, idx) => (
        <Accordion
        expanded={this.state.expanded == `list${id}${idx}Details`}
        onChange={this.handleExpand(`list${id}${idx}Details`)}
          key={`${id}-${idx}`}
          className="accordianPosProductDescription"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordian_spicelevel_summar"
            id="accordianPosProductDescriptionSummary"
          >
            Choose {title.toLowerCase()}...
          </AccordionSummary>
          <RadioGroup
            row
            className="categoryItemContainer"
            defaultValue=""
            value={this.showVal(id, idx)}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{paddingTop: "12px" }}
            
          >
            {this.state[`list${id}Details`] && this.state[`list${id}Details`].map((value: any) => (
              <FormControlLabel
                key={value.id}
                className="form_control_label_spicelevel"
                data-testId="spiceLevel"
                value={this.showVal(value.id, idx)}
                onChange={(e) =>
                  this.setDynamicState(
                    `${value.id}`,
                    id,
                    idx,
                    this.createArrayTocheckExtra(this.state[`list${id}Details`])
                  )
                }
                control={
                  <div>
                    <CustomPosRadioBox
                      style={{ position: "absolute", zIndex: 2, marginLeft: "3px" }}
                      checked={this.state.without_price_item.includes(Number(this.showVal(id, idx)))}
                    />
                    {this.itemContainer(value)}
                  </div>
                }
                label=""
              />
            ))}
          </RadioGroup>
        </Accordion>
      ));
    });
  };
  

  itemCustomizationAccordian = (customisations: CustomizationData[]) => {
    return customisations.map((customisation, idx) => {
      const customizationId = customisation.id;
      const itemCustomizations = this.state[`list${customizationId}ItemCustomisation`] || [];

      if (!itemCustomizations.length) return null;

      return (
        <Accordion
          key={`${customizationId}-${idx}`}
          className="accordianPosProductDescription"
          expanded={this.state.expanded == `list${customizationId}ItemCustomisation`}
          onChange={this.handleExpand(`list${customizationId}ItemCustomisation`)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="accordian_spicelevel_summary"
            id="accordianPosProductDescriptionSummary"
          >
            {customisation.title}...
          </AccordionSummary>
          <RadioGroup
            row
            className="categoryItemContainer"
            defaultValue=""
            value={this.showVal(customizationId, idx)}
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            style={{paddingTop: "12px" }}
          >
            {itemCustomizations.map((value: any) => (
              <FormControlLabel
                key={value.id}
                className="form_control_label_spicelevel"
                data-testId="spiceLevel"
                value={value.id}
                control={
                  <div>
                    <CustomGreenCheckbox
                      style={{ position: "absolute", zIndex: 2, marginLeft: "3px" }}
                      onChange={()=>{this.setItemsOnPriceBasis(value.id,this.state[`priceOption${customizationId}`],value.attributes.on_its_own_price)}}
                      checked={this.findCheckedStatus(Number(value.id))}
                    />
                    {this.itemContainer(value)}
                  </div>
                }
                label=""
              />
            ))}
          </RadioGroup>
        </Accordion>
      );
    });
  }

  getButtonContainer=()=>{
    return(
        <div className='button_container'>
          <div style={{display:"flex",gap:"12px",alignItems:"center"}}>
            <IconButton disabled={this.state.product_quantity<2} onClick={this.minusTheProductQuantity} className='product_minus_add_button'><RemoveRoundedIcon style={{color:"#286532"}}/></IconButton>
          <div style={{fontWeight:700}}>{String(this.state.product_quantity).padStart(2, '0')}</div>
          <IconButton onClick={this.addTheProductQuantity} className='product_minus_add_button'><AddRoundedIcon style={{color:"#286532"}}/></IconButton></div>
          <Button disabled={this.buttonDisabled()} onClick={this.addItemsToCart} style={{ height: '56px', color: this.buttonDisabled()?"#909090":"#fff", backgroundColor: this.buttonDisabled()?"#BFBFBF":"#E11B22", width:"400px", padding:"16px", boxSizing:"border-box"}}>ADD TO CART</Button>
        </div>
    )
  }

  // Customizable Area End

  render() {
    const { catalogueItemData, spiceLevelList } = this.props;

    if (!catalogueItemData) {
      return <CustomLoader loading={true} />;
    }

    const {
      attributes: {
        sub_sub_category,
        on_its_own_price,
        two_reg_sides_price,
        food_category,
        deal_products,
        item_customization,
      },
    } = catalogueItemData;

    const hasDeals: boolean = food_category === "Deals";
    const hasMains: boolean = food_category === "Mains";
    const hasDealProducts:boolean = !!deal_products && deal_products.length>0;
    const hasItemCustomization = item_customization?.data?.attributes?.customizations?.length > 0;

    return (
      <div className="productDescriptionContainer">
        {/* Product Description Header */}
        {this.productDescriptioHeader({
          title: sub_sub_category.title,
          on_its_own_price,
          reg_sides_price: two_reg_sides_price,
          hasMains, hasDeals
        })}

        {/* Deals Header */}
        {hasDeals && this.getDealsTitle(catalogueItemData.attributes) && (
          <div data-testid="sides-header" className="sides_description_header">
            <span className="item_information_subsubcategory">
              {this.getDealsTitle(catalogueItemData.attributes)} Included
            </span>
          </div>
        )}

        {/* Spice Level Accordian */}
        {(hasMains || hasDeals) && this.accordianContainer(spiceLevelList)}

        {/* Dealable Products or Choose Your Type */}
        {hasDealProducts
          ? this.dealableProducts(deal_products||[])
          : two_reg_sides_price &&
          this.accordianChooseYourType({
            two_reg_sides_price,
            on_its_own_price,
          })}

        {/* Item Customization Accordian */}
        {hasItemCustomization &&
          this.itemCustomizationAccordian(
            item_customization.data.attributes.customizations
          )}

        {/* Notes Accordian */}
        {this.notesAccordian()}
        
        {/* Button Container*/}
        {this.getButtonContainer()}
      </div>
    );
  }
  
}

