import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";

export interface SupportCategory {
  id: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    icon: {
      url: string;
      content_type: string;
      file_name: string;
    }
    support_contact_infos: SupportContactInfo[]
  }
}

export interface SupportContactInfo {
  id: number;
  name: string;
  email: string;
  phone: string;
}
export interface CategoryFaqSupport {
  id: string;
  type: string;
  attributes: {
    support_category: {
      id: number,
      title: string;
      description: string;
    }
    question: string;
    answer: string;
    image: {
      url: string;
      content_type: string;
      file_name: string;
    }
    support_contact_infos:SupportContactInfo[]
  }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  support_category_list: SupportCategory[];
  expanded:string | boolean;
  categoryWiseId:boolean;
  categorywise_faq_questions: CategoryFaqSupport[];
  title:string;
  description:string;
  id:string|number;
  searchInput:string;
  supportContactList:SupportContactInfo[];
  loading:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class Cfpossupportpage1Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  supportCategoryApiCallId :string="";
  supportFaqIdWiseApiCallId : string="";
  globalSearchApiCallId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      support_category_list:[],
      expanded:"",
      categoryWiseId:false,
      categorywise_faq_questions:[],
      title:"",
      description:"",
      id:"",
      searchInput:"",
      supportContactList:[],
      loading:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if(responseJson){
      if(apiRequestCallId === this.supportCategoryApiCallId){
        this.handleSupportApiResponse(responseJson)
      }
      if(apiRequestCallId === this.supportFaqIdWiseApiCallId){
        this.handleSupportCategoryWiseResponse(responseJson)
      }
      if(apiRequestCallId === this.globalSearchApiCallId){
        this.handleSupportViaSearch(responseJson)
      }
    } else if( errorReponse){
      console.log({errorReponse,message},"responsetobecalled")
    }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  componentDidMount =async()=>{
    this.getSupportContactCategories()
  }

  getSupportContactCategories=()=>{
    const token = localStorage.getItem("authToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.supportCategoryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getAllSupportCategoriesEndPoint
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSupportViaSearch=(responseJson:{category_faqs?:{data:CategoryFaqSupport[]},message?:string,error?:string})=>{
    if(responseJson.category_faqs){
      this.setState({categoryWiseId:true,categorywise_faq_questions:responseJson.category_faqs.data})
    }
  }

  handleSupportApiResponse=(responseJson:{message:string,support_categories:SupportCategory[],total_count:number})=>{
    if(responseJson.total_count === 0 ){
      this.setState({support_category_list:[]})
    } else if(responseJson.total_count > 0){
      this.setState({support_category_list: responseJson.support_categories})
    }
  }
  handleExpand =
    (panel: string) => (event: React.ChangeEvent<{}>, expanded: boolean) => {
      this.setState({ expanded: expanded ? panel : false });
    };


    getSupportFaqCategoryWise=(id:string|number,title:string,description:string,supportContactList:SupportContactInfo[])=>{
      this.setState({title,description,id,supportContactList})
      const token = localStorage.getItem("authToken")
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.supportFaqIdWiseApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getCategoryWiseFaqEndPoint}${id}/category_faqs`
      );
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getSearchResultGlobal=()=>{
      this.setState({title:"Search Result", description: `Searching for ${this.state.searchInput}.`})
      const token = localStorage.getItem("authToken")
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.globalSearchApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.getGlobalSearchEndPoint}${this.state.searchInput}`
      );
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  handleSupportCategoryWiseResponse=(responseJson:{category_faqs?:{data:CategoryFaqSupport[]},message?:string,error?:string})=>{
    if(responseJson.category_faqs){
      this.setState({categoryWiseId:true,categorywise_faq_questions:responseJson.category_faqs.data})
    }
  }
  closeCategoryWiseView=()=>{this.setState({categoryWiseId:false,title:"",description:"",id:""})}

  handleSearchQuery=(event: React.ChangeEvent<HTMLInputElement>)=>{
    const searchInput = event.target.value
    this.setState({searchInput})
  }
  getSupportFaqCategorySearch=()=>{
    const token = localStorage.getItem("authToken")
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.supportFaqIdWiseApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getCategoryWiseFaqEndPoint}${this.state.id}/category_faqs?search=${this.state.searchInput}`
    );
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: token,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  pressEnterToSearch=(event:React.KeyboardEvent<HTMLInputElement>)=>{
    if(event.key === "Enter"){
      this.state.id?  this.getSupportFaqCategorySearch() : this.getSearchResultGlobal()
    }
  }
  // Customizable Area End
}
