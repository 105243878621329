import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import { collection, collectionEnabled, delivery, deliveryEnabled, inStore, inStoreEnabled } from "../../assets";
// Customizable Area Start
import { OrderHistoryDetails } from "../../../../ordermanagement/src/OrderHistoryController";
import { CartDetails, OrderDetails } from "packages/blocks/cfposordermanagementfe/src/CfposPrductDescriptionController";
export const configJSON = require("./config");
export const configJSON1 = require("../../../../../framework/src/config")
export interface CustomerInformation{
  customerName: string;
  customerPhoneNumber: string;
  customerAddress: string;
}
export interface PreviousOrderInfo{
  date: string;
  status: string;
  amount: string;
}

export interface CustomerResponse {
    id: string;
    type: string;
    attributes: CustomerAttributes;
    default_address_postcode: string | null;
    previous_order: [Order[], PaginationData];
}

export interface CustomerAttributes {
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: number | null;
  full_phone_number: string | null;
  customer_name: string;
  customer_id: string | null;
  date_of_birth: string | null;
  gender: string | null;
  email_communication: boolean;
  mobile_communication: boolean;
  type: string;
  account_created_for: string | null;
}

export interface Order {
  id: string;
  type: string;
  attributes: OrderAttributes;
}

export interface OrderAttributes {
  id: number;
  order_number: string;
  restaurant: string | null;
  amount: number | null;
  account_id: number;
  promo_code_id: string | null;
  promo_code_name: string | null;
  coupon_code_id: string | null;
  redeem_point: number;
  points_worth: string;
  gift_card_ref_num: string | null;
  gift_card_amount: string;
  gift_card_id: string | null;
  delivery_address_id: string | null;
  sub_total: string;
  total: string;
  status: string;
  notes_to_chef: string | null;
  order_type: string | null;
  order_medium: string;
  pos_user_id: string | null;
  is_priority: boolean;
  prepare_immediately: boolean | null;
  schedule_time: string | null;
  custom_label: string | null;
  applied_discount: string;
  cancellation_reason: string | null;
  order_date: string | null;
  is_gift: boolean;
  placed_at: string | null;
  confirmed_at: string | null;
  cooking_at: string | null;
  ready_at: string | null;
  in_transit_at: string | null;
  delivered_at: string | null;
  cancelled_at: string | null;
  refunded_at: string | null;
  source: string | null;
  shipment_id: string | null;
  delivery_charges: string | null;
  tracking_url: string | null;
  payment_failed_at: string | null;
  payment_pending_at: string | null;
  returned_at: string | null;
  tax_charges: string;
  deliver_by: string | null;
  tracking_number: string | null;
  is_error: boolean;
  delivery_error_message: string | null;
  order_status_id: number;
  is_group: boolean;
  is_availability_checked: boolean;
  shipping_charge: string | null;
  shipping_discount: string | null;
  shipping_net_amt: string | null;
  shipping_total: string | null;
  total_tax: string | null;
  created_at: string;
  updated_at: string;
  delivery_addresses: any[];
  razorpay_order_id: string | null;
  charged: string | null;
  invoice_id: string | null;
  invoiced: boolean;
  order_items: any[];
  personal_detail: PersonalDetail;
  payment_transaction: PaymentTransaction | null;
  account: Account;
}

export interface PersonalDetail {
  id: number;
  first_name: string;
  last_name: string;
  full_phone_number: string;
  order_id: number;
  created_at: string;
  updated_at: string;
}

export interface PaymentTransaction {
  id: number;
  account_id: number;
  charge_id: string;
  amount: number;
  currency: string;
  charge_status: string;
  created_at: string;
  updated_at: string;
  transactionable_type: string;
  transactionable_id: number;
  payment_method_type: string;
  transaction_number: string;
}

export interface Account {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

export interface AccountAttributes {
  activated: boolean;
  country_code: string | null;
  email: string;
  first_name: string;
  full_phone_number: string;
  last_name: string;
  phone_number: string | null;
  type: string;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
}

export interface PaginationData {
  total_count: number;
  current_page: number;
  next_page: number | null;
  prev_page: number | null;
}

// Customizable Area End

export const webConfigJSON = require("../../config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  loading: boolean;
  customerInformation:CustomerInformation;
  orderTypeSelection:string;
  callStatus:string;
  previousOrderList:PreviousOrderInfo[];
  customerSearchInputText: string;
  customerData:  CustomerResponse;
  page:number;
  editCustomerModal:boolean;
  isEditing:boolean;
  errors: Partial<{
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    date_of_birth: string;
    address: string;
    postCode: string;
  }>;
  newCustomer: {
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    date_of_birth: string;
    gender:string;
    address: {

      city:string;
      country: string;
      address: string;
      postCode: string;
      addressDirection: string;
      defaultAddress: boolean;
    };
    email_communication?: boolean;
    mobile_communication?: boolean;
  };
  marketingModalOpen:boolean;
  editingCustomerId:string;
  token:string;
  successModal:boolean;
  btnText:string;
  successMsg:string;
  img:string;
  marketingBody:{email_communication:boolean;mobile_communication:boolean},
  orderDetails: OrderHistoryDetails | null;
  viewOrderDetailsOpen:boolean;
  reorderId:string;
  // Customizable Area End
}
interface SS {}

export default class PosOrderCreationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  getSearchCustomerApiCallId:string = "";
  updateCustomerDetailApiCallId:string="";
  updateCustomerMarketingApiCallId:string="";
  setOrderDetailsApiCallId:string="";
  createOrderApiCallId:string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.createOrderApiCallId=""
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      loading: false,
      customerInformation:{
      customerName: "",
      customerPhoneNumber: "",
      customerAddress: "" },
      orderTypeSelection:"",
      callStatus:"Down",
      previousOrderList:[{amount:"23.5",date:"22/04/2024",status:"Ready"},{amount:"23.5",date:"22/04/2024",status:"Ready"}],
      customerSearchInputText:"",
      customerData:{
        id: "",
        type: "",
        attributes: {
          id: 0,
          first_name: "",
          last_name: "",
          email: "",
          phone_number: null,
          full_phone_number: null,
          customer_name: "",
          customer_id: null,
          date_of_birth: null,
          gender: null,
          email_communication: false,
          mobile_communication: false,
          type: "",
          account_created_for: null,
        },
        default_address_postcode: null,
        previous_order: [[],{prev_page:null, next_page:null,current_page:1,total_count:1}],
      },
      page:1,
      editCustomerModal:false,
      isEditing:false,
      errors:{},
      newCustomer: {
        firstName: "",
        lastName:"",
        email: "",
        countryCode: "",
        phoneNumber: "",
        date_of_birth: "4",
        gender:"",
        address: {
    
          city:"",
          country: "",
          address: "",
          postCode: "",
          addressDirection: "",
          defaultAddress: false,
        },
        email_communication: false,
        mobile_communication: false,
      },
      marketingModalOpen:false,
      editingCustomerId:"",
      token:"",
      successModal:false,
      btnText:"",
      successMsg:"",
      img:"",
      marketingBody:{email_communication:false,mobile_communication:false},
      orderDetails:null,
      viewOrderDetailsOpen:false,
      reorderId:"",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    let token = localStorage.getItem("authToken")||"";
    if (token) {
      this.setState({ token })
    }
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      runEngine.debugLog("API Message Recived Properly", message);
        if(apiRequestCallId === this.getSearchCustomerApiCallId){
          this.handleCustomerSearchResult(responseJson)
        }
        if(apiRequestCallId === this.updateCustomerDetailApiCallId){
          this.handleUpdatedCustomerData(responseJson)
        }
        if(apiRequestCallId === this.updateCustomerMarketingApiCallId){
          this.handleUpdateMarketingInfo(responseJson)
        }
        if(apiRequestCallId === this.setOrderDetailsApiCallId){
          this.handleOrderDetails(responseJson)
        }
        if(apiRequestCallId === this.createOrderApiCallId){
          this.orderCreationNavigation(responseJson)
        }
    }
    // Customizable Area End
  }
  // Customizable Area Start

  componentDidUpdate=async(prevProps: Readonly<Props>, prevState: Readonly<S>)=> {
    if(this.state.page !== prevState.page){
      this.searchCustomer()
    }
  }

  orderTypeSelectionArray=()=>{
    const {orderTypeSelection} = this.state
    let imageArray = [{imagePath:delivery, title:"Delivery", class:"unSelectedOrderType",value:"delivery"},{imagePath:collection, title:"Collection", class:"unSelectedOrderType",value:"collection"},{imagePath:inStore, title:"In Store", class:"unSelectedOrderType",value:"store_type"}]
    if(orderTypeSelection === "delivery"){
        imageArray[0].imagePath = deliveryEnabled
        imageArray[0].class = "selectedOrderType"
    } else if( orderTypeSelection === "collection"){
      imageArray[1].imagePath = collectionEnabled
      imageArray[1].class = "selectedOrderType"
    } else if(orderTypeSelection === "store_type"){
      imageArray[2].imagePath = inStoreEnabled
      imageArray[2].class = "selectedOrderType"
    }
    return imageArray
  }

  provideClassNameWithStatusType=(status:string):string=>{
    if(status == "Approved"){
      return "approved_tag"
    } else if(status == "Pending"){
      return "pending_tag"
    } else  if(status == "Declined"){
      return "declined_tag"
    } else  if(status == "Ready"){
      return "ready_tag"
    } else  if(status == "Picked Up"){
      return "picked_up_tag"
    } else{
      return "delivered_tag"
    } 
  }

  onChangeOrderType=(event:any)=>{
    console.log(event.currentTarget.accessKey,"navigationPayload")
    this.setState({orderTypeSelection: event.currentTarget.accessKey})
  }

  onChangeSearchText=(event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=>{
    this.setState({customerSearchInputText:event.target.value})
  }

  searchCustomer=()=>{
    const token = localStorage.getItem("authToken")
    this.setState({loading:true})
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSearchCustomerApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.findCustomerDetail}${this.state.customerSearchInputText}&page=${this.state.page}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getAddressApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleCustomerSearchResult=(responseJson:{message?:string,customers?:CustomerResponse,errors?:any})=>{
    if(responseJson.message && responseJson.customers){
      this.setState({customerData:responseJson.customers,loading:false})
    } else if( responseJson.message && responseJson.message === "Customer not found."){
      this.setState({img:"failure",successMsg:responseJson.message,btnText:"Add Customer",successModal:true,loading:false})
    }else if(responseJson.errors && responseJson.errors[0].token){
      this.showAlert('token','expired')
    }
  }

  handlePageChange=(event: React.ChangeEvent<unknown>,page:number)=>{
    this.setState({page})
  }

  openEditModal=()=>{
    const customers = this.state.customerData.attributes
    this.setState({editCustomerModal:true,isEditing:true, editingCustomerId:`${customers.id}`,newCustomer:{
      ...this.state.newCustomer,
      firstName:customers.first_name,
      lastName: customers.last_name,
      email: customers.email,
      phoneNumber: customers.full_phone_number||"",
      countryCode:"",
      date_of_birth:customers.date_of_birth || "",
      gender: customers.gender ||"",
      email_communication:customers.email_communication,
      mobile_communication: customers.mobile_communication
    }})
  }

  openAddModal=()=>{
    if(this.state.successMsg.includes("Customer Information")){
      this.setState({editCustomerModal:false,isEditing:false,successModal:false,customerSearchInputText:""})
    } else if(this.state.successMsg.includes("Marketing Information")){
      this.setState({editCustomerModal:false,isEditing:false,successModal:false,marketingModalOpen:false})
    }
    else{
      this.setState({editCustomerModal:true,isEditing:false,successModal:false})
    }
  }

  closeEditModal=()=>{
    this.setState({editCustomerModal:false,newCustomer:{
      ...this.state.newCustomer,
      firstName:"",
      lastName: "",
      email: "",
      phoneNumber: "",
      countryCode:"",
      date_of_birth:"",
      gender: "4",
      email_communication:false,
      mobile_communication: false
    }})
  }

  addOrUpdateCustomer = (formdata: any) => {
    if( !this.validateCustomerForm({
      firstName: formdata.firstName,
      lastName: formdata.lastName,

      email: formdata.email,
      countryCode: formdata.countryCode,
      phoneNumber: formdata.phoneNumber,
      date_of_birth: formdata.date_of_birth,
      address: {
        address:  formdata.address.addressDirection,
        postCode: formdata.address.postCode,
        city: "ghaziabad",
        country: "UK",
        addressDirection:  formdata.address.addressDirection,
        defaultAddress:  true,
      },
    })){
      console.error("Form validation failed.");
      return;
    }

    const {token,editingCustomerId,isEditing} = this.state

    if(token){
      this.setState({loading:true})
      const body = !isEditing
      ? {
          data: {
            attributes: {
              email: formdata.email,
              first_name: formdata.firstName,
              last_name: formdata.lastName,
              full_phone_number: formdata.phoneNumber,
              date_of_birth: formdata.date_of_birth,
              gender:formdata.gender,
              addresses_attributes: [
                {
                  city: formdata?.address?.city || '',
                  country: '',
                  address: formdata.address.addressDirection,
                  post_code: formdata.address.postCode,
                  address_direction: formdata.address.addressDirection,
                  default_address: true,
                },
              ],
            },
            device: "web",
          },
        }
      : {
        data: {
          attributes: {
            first_name: formdata.firstName,
            last_name: formdata.lastName,
            email: formdata.email,
            gender:formdata.gender,
            full_phone_number: formdata.phoneNumber,
            name: '',
            date_of_birth: formdata.date_of_birth,
            address_type: "",
            address: formdata.address.addressDirection,
            default_address: formdata.default_address,
            post_code: formdata.address.postCode,
            country: "",
            city: "",
            // latitude: 28.5491844,
            // longitude: 77.3460833,
            address_direction: formdata.address.addressDirection,

          },
        },
      };
      const header = {
        "Content-Type": configJSON.getAddressApiContentType,
        token
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.updateCustomerDetailApiCallId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        isEditing ? `${configJSON.customerListEndPoint}/${editingCustomerId}` : configJSON.customerListEndPoint
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        isEditing ? configJSON.updateAddressApiMethod : configJSON.postAddressApiMethod
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleResponse = (response: any) => {
    if (response.errors) {
      const errors: Record<string, string> = {};
      if (response.errors[0].account) {
        if (response.errors[0].account.includes("Email"))
          errors.email = response.errors[0].account;
      }else if(response.errors[0].token){
        this.showAlert('token','expired')
      } else {
        if (response.errors[0].includes("Phone")) errors.phoneNumber = response.errors[0];
      }
      this.setState({ errors, loading:false });
    }
    else if (!response.errors) {
      if (this.state.isEditing) {
        const { first_name, last_name, full_name, phone_number, full_phone_number, gender, date_of_birth, email } = response.customer
        this.setState({
          loading: false, editCustomerModal: false, isEditing: false, successModal: true, img: "success", btnText: "Close", successMsg: "Customer Information has been updated successfully!",
          customerData: {
            ...this.state.customerData, attributes: {
              ...this.state.customerData.attributes, first_name, last_name, customer_name: full_name, phone_number, full_phone_number, gender, date_of_birth, email
            }
          }
        });
      } else {
        const { data } = response.account
        const customerData = data.attributes
        const { first_name, last_name, phone_number, full_phone_number, gender, date_of_birth, email } = customerData
        this.setState({
          loading: false, editCustomerModal: false, isEditing: false, successModal: true, img: "success", btnText: "Close", successMsg: "Customer Information has been added successfully!", customerSearchInputText: "",
          customerData: {
            id: data.id, type: "customer", attributes: {
              ...this.state.customerData.attributes, id: Number(data.id), first_name, last_name, date_of_birth, gender, phone_number, full_phone_number, email, customer_name: first_name + last_name
            }, previous_order: [[], { prev_page: null, next_page: null, current_page: 1, total_count: 1 }], default_address_postcode: null
          }
        });
      }
    }
  };

  openMarketingInfoModal=()=>{
    const customers = this.state.customerData.attributes
    this.setState({marketingModalOpen:true,isEditing:true,editingCustomerId:`${customers.id}`, newCustomer:{
      ...this.state.newCustomer,
      firstName:customers.first_name,
      lastName: customers.last_name,
      email: customers.email,
      phoneNumber: customers.full_phone_number||"",
      countryCode:"",
      date_of_birth:customers.date_of_birth || "",
      gender: customers.gender ||"",
      email_communication:customers.email_communication,
      mobile_communication: customers.mobile_communication
    }})
  }

  closeMarketingInfoModal=()=>{
    this.setState({marketingModalOpen:false,newCustomer:{
      ...this.state.newCustomer,
      firstName:"",
      lastName: "",
      email: "",
      phoneNumber: "",
      countryCode:"",
      date_of_birth:"",
      gender: "",
      email_communication:false,
      mobile_communication: false
    }})
  }

  addMarketingInfoModal = (body: any,id:any) => {
    const {token} = this.state
    if(token){
      this.setState({loading:true})
      this.changeCommunicationBody(body)
    const header = {
      "Content-Type": configJSON.getAddressApiContentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCustomerMarketingApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.marketingInfoUpdateEndPoint}/${id}/marketing_communication_permission`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.patchAddressApiMethod
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  }

  validateCustomerForm = (newCustomer: any) => {
    const errors: Record<string, string> = {};

    if (!newCustomer.firstName.trim()) errors.firstName = "This field should not be left empty";
    if (!newCustomer.lastName.trim()) errors.lastName = "This field should not be left empty";
    if (/\s/.test(newCustomer.lastName)) {
      errors.lastName = "First name should not contain spaces between characters.";
    }
    if (!newCustomer.email.trim()) errors.email = "This field should not be left empty";
    else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newCustomer.email)) errors.email = "The email address you entered is not in a valid format. Please enter a valid email address";
    if (!newCustomer.phoneNumber) errors.phoneNumber = "This field should not be left empty";
    else if (newCustomer.phoneNumber.length < 9 || newCustomer.phoneNumber.length > 12) {
      errors.phoneNumber = "The mobile number you entered is not in a valid format. Please enter a valid mobile number";
    }
    if (!newCustomer.address.address.trim()) errors.address = "This field should not be left empty";
    if (newCustomer.address.address.trim().length > 100) errors.addressDirection = "This field should not exceed 100 characters. Please adjust the input.";
    if (!newCustomer.address.postCode.trim()) errors.postCode ="This field should not be left empty";
    if (new Date(newCustomer.date_of_birth) > new Date()) {
      errors.date_of_birth = "Date of birth cannot be in the future.";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0; // Return true if no errors
  };

  handleUpdatedCustomerData=(responseJson:any)=>{
    this.handleResponse(responseJson)
  }

  disableDetailsMarketing=():boolean=>{
    return !this.state.customerData.attributes.customer_name
  }

  changeCommunicationBody=(body:{email_communication:boolean;mobile_communication:boolean})=>{
    this.setState({marketingBody:body})
  }

  handleUpdateMarketingInfo=(responseJson:any)=>{
    if(responseJson.message && responseJson.success && responseJson.message === "Preferences updated successfully"){
      const { email_communication, mobile_communication} = this.state.marketingBody
      this.setState({ loading: false, marketingModalOpen: false, isEditing: false, successModal: true, img: "success", btnText: "Close", successMsg: "Marketing Information has been updated successfully!",
        customerData:{...this.state.customerData, attributes:{...this.state.customerData.attributes, email_communication,mobile_communication}}})
    }else if(responseJson.errors && responseJson.errors[0].token){
      this.showAlert('token','expired')
    }
  }
  getOrderDetails = (event:React.MouseEvent<HTMLButtonElement>)=>{
    const id = event.currentTarget.id
    const {token,customerData} = this.state
    const acc_id = customerData.id
    if(token){
      this.setState({loading:true})
      const headers = {
        "Content-Type": configJSON.getAddressApiContentType,
        token
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.setOrderDetailsApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getOrderDetailsApiEndPoint}${id}/order_detail?account_id=${acc_id}`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getAddressApiMethod
      );
      
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }

  handleOrderDetails=(responseJson:{data?:{data:OrderHistoryDetails},errors?:any})=>{
    this.setState({loading:false})
    if(responseJson.errors){
      if(responseJson.errors[0].token){
        this.showAlert('token','expired')
      }
     } else if(responseJson.data){
      this.setState({orderDetails:responseJson.data.data,viewOrderDetailsOpen:true})
     }
  }

  downloadInvoice=(id:string)=> {
    const url = `${configJSON1.baseURL}/${configJSON.getOrderDetailsApiEndPoint}/${id}/download_invoice?token=${this.state.token}&account_id=${this.state.customerData.id}`
    location.href= url
  }

  closeViewOrderDetails=()=>{
    this.setState({viewOrderDetailsOpen:false,orderDetails:null})
  }

  navigateToOrderManagement=(order_type?:string)=>{
    const message: Message = new Message(
      getName(MessageEnum.NavigationPosOrderManagementMessage)
    );
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    message.addData(getName(MessageEnum.NavigationPayLoadMessage),{cust_id:this.state.customerData.id,order_type:order_type||this.state.orderTypeSelection})
    this.send(message);
}

createOrderApi = () => {
  const body = {
    account_id: this.state.customerData.attributes.id,
    order_type:this.state.orderTypeSelection,
  };

  const headers = {
    'Content-Type': configJSON.getAddressApiContentType,
    token: this.state.token
  };


  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.createOrderApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.addItemsToCartApiEndPoint
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postAddressApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

orderCreationNavigation=(responseJson:{cart_details?:CartDetails,order_details?:OrderDetails,error?:any,meta?:{success:boolean,message:string},data?:any})=>{
  if(responseJson.order_details){
    this.navigateToOrderManagement()
  }else if(responseJson.meta && responseJson.meta.success && responseJson.data){
    this.navigateToOrderManagement(responseJson.data.attributes.order_type)
  }
  else{
    // this.showAlert("token","token")
  }
}

changeReorderIcons=(event:React.ChangeEvent<HTMLInputElement>)=>{
  this.setState({reorderId:event.target.value})
}

reorderApiCall = () => {
  const body = {
    account_id: this.state.customerData.attributes.id,
  };

  const headers = {
    'Content-Type': configJSON.getAddressApiContentType,
    token: this.state.token
  };


  const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.createOrderApiCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.getOrderDetailsApiEndPoint}/${this.state.reorderId}/reorder`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.postAddressApiMethod
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  // Customizable Area End
}
