import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box, Dialog, DialogContent, Typography, DialogTitle, IconButton} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

interface Props extends React.PropsWithChildren<any> {
    open: boolean;
    onClose: any;
    closeIcon?: boolean
    classes: any;
    dataTestId?:string;
    maxHeight?:string;
}

const styles = (theme: any) => ({
  dialogPaper: {
    height: 'calc(100% - 64px) !important',
    width: '100% !important',
    maxWidth: '1000px !important',
    maxHeight: '1055px !important',
  },
});

const PosProductDialogPopup =({open,onClose,closeIcon=true,classes,children}:Props)=> {
    return (
      <Dialog open={open} onClose={onClose}
      className='dialog_for_popup'
      classes={{ paper: classes.dialogPaper }}
      PaperProps={{ 
        style: { 
          borderRadius: '16px',
        } 
      }} >
        {closeIcon && <DialogTitle style={{padding:"8px 24px 0px 24px"}}>
          <Box display="flex" alignItems="center" justifyContent="end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>}
        <DialogContent style={{padding: "0px",maxHeight: 'calc(100% - 48px)', height: '100%'}}>
          {children}
        </DialogContent>
      </Dialog>
    );
}

export default withStyles(styles)(PosProductDialogPopup);
