import React from "react";
import moment from "moment";
import { Event } from "react-big-calendar";
import { FormControl, FormHelperText, IconButton, MenuItem, Select, SelectChangeEvent, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { CheckRounded, CloseRounded } from "@material-ui/icons";
import Modal from "../../../../Modal";
import { WingoInput } from "../../../../Inputs/CustomInputs";
import { ContainedButton, OutlinedButton } from "../../../../Button";
import { getSessionStorage } from "../../../../utils";
import FormLabel from "../../../../FormLabel";
import "./LoginLogoutModal.css";

interface Props {
  open: boolean;
  onClose: () => void;
  login: (data: {
    id: number;
    login_time: string;
  }) => void;
  logout: (data: {
    id: number;
    logout_time: string;
  }) => void;
  event: Event | null;
};

const LoginLogoutModal = ({ open, onClose, login, logout, event }: Props) => {
  const settings = getSessionStorage("restaurant_detail");
  const time_format = settings?.time_format;
  const is24HourFormat = time_format === "24-hour";

  const [loginTime, setLoginTime] = React.useState<string>("");
  const [logoutTime, setLogoutTime] = React.useState<string>("");

  const shift = React.useMemo(() => event, [event]);
  const resource = React.useMemo(() => event?.resource, [event]);

  const hourOptions = Array(12).fill(0).map((_, i) => {
    i++;
    return i.toString().padStart(2, '0');
  });
  const minuteOptions = Array(60).fill(0).map((_, i) => i.toString().padStart(2, '0'));

  const startDate = moment(shift?.start).format("yyyy-MM-DD");
  const isLoggedIn = resource?.login_details?.is_logged_in;
  const isLoggedOut = resource?.login_details?.is_logged_out;

  React.useEffect(() => {
    const timeString = resource?.login_details?.login ? moment(resource?.login_details?.login).format(is24HourFormat ? "HH:mm" : "hh:mm A") : "";

    setLoginTime(timeString);
  }, [resource?.login_details?.login]);

  React.useEffect(() => {
    const timeString = resource?.login_details?.logout ? moment(resource?.login_details?.logout).format(is24HourFormat ? "HH:mm" : "hh:mm A") : "";

    setLogoutTime(timeString);
  }, [resource?.login_details?.logout])

  const handleSubmit = () => {
    if (!isLoggedIn) {
      if(!loginTime || loginTime.includes("undefined")) return;
      login({ id: event?.resource.staff_id, login_time: moment(loginTime, ["hh:mm A"]).format("HH:mm") });
    } else {
      if(!logoutTime || logoutTime.includes("undefined")) return;
      logout({ id: event?.resource.staff_id, logout_time: moment(logoutTime, ["hh:mm A"]).format("HH:mm") });
    }
  };

  const getValue = (timeString: string, name: string) => {
    if (!timeString) return moment().format("hh:mm A");

    let [time, period] = timeString.split(" ");
    let [hour, minute] = time.split(":");

    const _: Record<string, string> = { hour, minute, period };

    return _[name];
  };

  const handleLoginTime = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    setLoginTime((prevTime) => {
      const [timePart, period] = prevTime.split(" ");
      let [hour, minute] = timePart.split(":");

      // Update hour or minute based on name
      if (name === "hour") hour = value;
      if (name === "minute") minute = value;

      // Rebuild the time string
      return is24HourFormat
        ? `${hour}:${minute}`
        : `${hour}:${minute} ${period || "AM"}`; // Add a fallback for the period
    });
  }

  const handleLogoutTime = (event: SelectChangeEvent<string>) => {
    const { name, value } = event.target;

    setLogoutTime((prevTime) => {
      const [timePart, period] = prevTime.split(" ");
      let [hour, minute] = timePart.split(":");

      // Update hour or minute based on name
      if (name === "hour") hour = value;
      if (name === "minute") minute = value;

      // Rebuild the time string
      return is24HourFormat
        ? `${hour}:${minute}`
        : `${hour}:${minute} ${period || "AM"}`; // Add a fallback for the period
    });
  };

  const handlePeriodChange = (value: string, login: boolean = true) => {
    if (login) {
      setLoginTime((prevTime) => {
        let [timePart, period] = prevTime.split(" ");
        const [hour, minute] = timePart.split(":");

        period = value

        // Rebuild the time string
        return is24HourFormat
          ? `${hour}:${minute}`
          : `${hour}:${minute} ${period || "AM"}`; // Add a fallback for the period
      });
    } else {
      setLogoutTime((prevTime) => {
        let [timePart, period] = prevTime.split(" ");
        const [hour, minute] = timePart.split(":");

        period = value

        // Rebuild the time string
        return is24HourFormat
          ? `${hour}:${minute}`
          : `${hour}:${minute} ${period || "AM"}`; // Add a fallback for the period
      });
    };
  };

  return (
    <Modal maxWidth="sm" open={open} onClose={onClose}>
      <div className="calendar__login__modal">
        <div className="calendar__login__modal__header">
          <h3>{shift?.title}</h3>
          <IconButton onClick={onClose}>
            <CloseRounded />
          </IconButton>
        </div>

        <div className="calendar__login__modal__body">
          <FormControl>
            <FormLabel>Date Applied</FormLabel>
            <WingoInput
              readOnly
              value={startDate}
            />
          </FormControl>

          <h4>Schedule</h4>

          <div className="calendar__login__modal__login">
            <h5>Log in</h5>

            <div className="calendar__login__modal__timer">
              <FormControl>
                <Select
                  readOnly={isLoggedIn}
                  name="hour"
                  value={getValue(loginTime, "hour")}
                  onChange={handleLoginTime}
                  classes={{
                    root: "calendar__login__select__root",
                    select: "calendar__login__select",
                    icon: "calendar__login__select__icon",
                  }}
                >
                  {hourOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>

                <FormHelperText classes={{ root: "calendar__login__timer__label" }}>Hour</FormHelperText>
              </FormControl>

              <span className="calendar__login__timer__separator">:</span>

              <FormControl>
                <Select
                  readOnly={isLoggedIn}
                  name="minute"
                  value={getValue(loginTime, "minute")}
                  onChange={handleLoginTime}
                  classes={{
                    root: "calendar__login__select__root",
                    select: "calendar__login__select",
                    icon: "calendar__login__select__icon",
                  }}
                >
                  {minuteOptions.map((option) => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>

                <FormHelperText classes={{ root: "calendar__login__timer__label" }}>Minute</FormHelperText>
              </FormControl>

              {!is24HourFormat && (
                <ToggleButtonGroup
                  exclusive
                  disabled={isLoggedIn}
                  orientation="vertical"
                  value={getValue(loginTime, "period")}
                  onChange={(_event, value) => handlePeriodChange(value)}
                >
                  <ToggleButton value="AM" classes={{ root: "calendar__login__timer__period", selected: "calendar__login__timer__period selected" }}>AM</ToggleButton>
                  <ToggleButton value="PM" classes={{ root: "calendar__login__timer__period", selected: "calendar__login__timer__period selected" }}>PM</ToggleButton>
                </ToggleButtonGroup>
              )}
              <div className="calendar__login__timer__checkbox" data-active={isLoggedIn}>
                <CheckRounded width={16} height={16} />
              </div>
            </div>
          </div>
          {isLoggedIn && (
            <div className="calendar__login__modal__login">
              <h5>Log out</h5>

              <div className="calendar__login__modal__timer">
                <FormControl>
                  <Select
                    readOnly={isLoggedOut}
                    name="hour"
                    value={getValue(logoutTime, "hour")}
                    onChange={handleLogoutTime}
                    classes={{
                      root: "calendar__login__select__root",
                      select: "calendar__login__select",
                      icon: "calendar__login__select__icon",
                    }}
                  >
                    {hourOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>

                  <FormHelperText classes={{ root: "calendar__login__timer__label" }}>Hour</FormHelperText>
                </FormControl>

                <span className="calendar__login__timer__separator">:</span>

                <FormControl>
                  <Select
                    readOnly={isLoggedOut}
                    name="minute"
                    value={getValue(logoutTime, "minute")}
                    onChange={handleLogoutTime}
                    classes={{
                      root: "calendar__login__select__root",
                      select: "calendar__login__select",
                      icon: "calendar__login__select__icon",
                    }}
                  >
                    {minuteOptions.map((option) => (
                      <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                  </Select>

                  <FormHelperText classes={{ root: "calendar__login__timer__label" }}>Minute</FormHelperText>
                </FormControl>

                {!is24HourFormat && (
                  <ToggleButtonGroup
                    exclusive
                    disabled={isLoggedOut}
                    orientation="vertical"
                    value={getValue(logoutTime, "period")}
                    onChange={(_event, value) => handlePeriodChange(value, false)}
                  >
                    <ToggleButton value="AM" classes={{ root: "calendar__login__timer__period", selected: "calendar__login__timer__period selected" }}>AM</ToggleButton>
                    <ToggleButton value="PM" classes={{ root: "calendar__login__timer__period", selected: "calendar__login__timer__period selected" }}>PM</ToggleButton>
                  </ToggleButtonGroup>
                )}
                <div className="calendar__login__timer__checkbox" data-active={isLoggedOut}>
                  <CheckRounded width={16} height={16} />
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="calendar__login__modal__footer">
          <OutlinedButton onClick={onClose}>CANCEL</OutlinedButton>
          <ContainedButton disabled={isLoggedIn && isLoggedOut} onClick={handleSubmit}>SAVE</ContainedButton>
        </div>
      </div>
    </Modal>
  );
};

export default LoginLogoutModal;
