import * as React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Radio, { RadioProps } from '@material-ui/core/Radio';

const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 5,
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
        borderRadius: 4,
        width: 16,
        height: 16,
        backgroundColor: '#2C6F37',
        position: 'relative',
        '&:before': {
          content: '""',
          position: 'absolute',
          left: '5px',
          top: '2px',
          width: '4px',
          height: '8px',
          border: 'solid white',
          borderWidth: '0 2px 2px 0',
          transform: 'rotate(45deg)',
      },
      'input:hover ~ &': {
        backgroundColor: '#2C6F37',
      },
    },
  });
  

  export default function CustomPosRadioBox(props: RadioProps) {
    const classes = useStyles();
  
    return (
      <Radio
        className={classes.root}
        disableRipple
        color="default"
        checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
        icon={<span className={classes.icon} />}
        checked={props.checked}
        {...props}
      />
    );
  }
