import React from "react";

// Customizable Area Start
import {
  StyleSheet,
  ScrollView,
  TouchableWithoutFeedback
} from "react-native";

// Customizable Area End

import CfposPrductDescriptionController, {
  Props,
} from "./CfposPrductDescriptionController";

export default class CfposPrductDescription extends CfposPrductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start
    return (
      <ScrollView keyboardShouldPersistTaps="always" style={styles.container}>
        <TouchableWithoutFeedback
        data-test-id="touchableWithoutFeedback">
        </TouchableWithoutFeedback>
      </ScrollView>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    marginLeft: "auto",
    marginRight: "auto",
    width: "100%",
    maxWidth: 650,
    backgroundColor: "#ffffffff",
  }
});
// Customizable Area End
