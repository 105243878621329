import React from "react";


// Customizable Area Start

import "./Cfposcustomermanagementfe.web.css";
import DashboardWrapper from "../../../components/src/Dashboard/DashboardWrapper";

import {
  SvgIcon,
  Typography as MuiTypography,
  Button as MuiButton,
  Modal,

} from "@mui/material";



import {
  SearchRounded
} from "@mui/icons-material";


// Customizable Area End

import CfposcustomermanagementfeController, {
  Props,
} from "./CfposcustomermanagementfeController";
import Header from "../../../components/src/Dashboard/Header/Header";
import { BaseInput } from "../../../components/src/Inputs/CustomInputs";
import CustomerList from "../../../components/src/Dashboard/CustomerInformation/CustomerList";
import AddOrUpdateCustomer from "../../../components/src/Dashboard/AddOrUpdateCustomer";
import CustomerMarketingInfo from "../../../components/src/Dashboard/CustomerMarketingInfo";
import InfoSuccess from "../../../components/src/Dashboard/CustomerMarketingInfo/InfoSuccess";
import CustomLoader from "../../../components/src/CustomLoader.web";


export default class Cfposcustomermanagementfe extends CfposcustomermanagementfeController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  searchRef = React.createRef<HTMLInputElement>();


  handlePageChange=(page: any)=> {
    const changeEvent = {
      target: {value:"test value"},
    } as React.ChangeEvent<HTMLInputElement>;
    this.fetchCustomers(changeEvent,page)
  };

  handleFocus = () => {
    this.searchRef.current?.focus();
  }

  searchStaff = () => {
    return (
      <div
        data-testid="search"
        className="search__wrapper"
        onClick={this.handleFocus}
      >
        <SearchRounded fontSize="small" />
        <BaseInput
          inputRef={this.searchRef}
          placeholder="Search Customer"
          value={this.state.query}
          onChange={this.handleSearchQuery}
          data-test-id={"searchInput"}
        />
      </div>
    );
  };
  // Customizable Area End

  render() {
    
    return (
      // Customizable Area Start
      <>
      <CustomLoader loading={this.state.loading}/>
        <DashboardWrapper navigation={this.props.navigation}>
          <div className="header-container">
            <Header
              navigation={this.props.navigation}
              headerTitle="Customer Management"
              search={this.searchStaff()}
            />
            
            <MuiButton
              disabled={false}
              data-testid="add-new-staff"
              classes={{
                root: "staff_info__add__button",
                text: "staff_info__add__button__text",
                disabled: "staff_info__add__button__disabled",
              }}
              startIcon={
                <SvgIcon>
                  <svg
                    width={800}
                    height={800}
                    viewBox="0 0 24 24"
                    data-name="Line Color"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 12h14m-7-7v14"
                      style={{
                        fill: "none",
                        stroke: "#fff",
                        strokeLinecap: "round",
                        strokeLinejoin: "round",
                        strokeWidth: 2,
                      }}
                    />
                  </svg>
                </SvgIcon>
              }
              onClick={this.openAddModal}
            >
              Add New
            </MuiButton>
          </div>
          <div className="customer_information__content">
            {(
              <CustomerList
                data={this.state.customers}
                totalCount={this.state.totalCount}
                totalPages={Math.ceil(this.state.totalCount / 20)}
                currentPage={this.state.currentPage}
                handleViewDetails={this.openViewModal}
                handleSelectedRow={this.openEditModal}
                handlePageChange={this.fetchCustomers}
                handleMarketingInfoOpen={this.openMarketingInfoModal}
              />
            )}
          </div>
        </DashboardWrapper>
        {this.state.viewModalOpen && <Modal
          open={this.state.viewModalOpen}
          onClose={this.closeViewModal}
          aria-labelledby="customer-details-modal"
          aria-describedby="customer-details-description"
        >
          <div className="modal-content">
            <MuiTypography variant="h6" id="customer-details-modal">
              Customer Details
            </MuiTypography>
            <MuiTypography variant="body1">
              <strong>Name:</strong> {this.state.selectedCustomer.attributes.customer_name || "N/A"}
            </MuiTypography>
            <MuiTypography variant="body1">
              <strong>Email:</strong> {this.state.selectedCustomer.attributes.email || "N/A"}
            </MuiTypography>
            <MuiTypography variant="body1">
              <strong>Phone Number:</strong> {this.state.selectedCustomer.attributes.full_phone_number ? "+"+this.state.selectedCustomer.attributes.full_phone_number	: "N/A"}
            </MuiTypography>
            <MuiTypography variant="body1">
              <strong>Address:</strong>
            </MuiTypography>
            <ul>
              {this.state.selectedCustomer.addresses.map((address: any) => (
                <li key={address.id}>
                  {address.attributes.address}, {address.attributes.post_code}, {address.attributes.country}
                </li>
              ))}
            </ul>
            <MuiTypography variant="body1">
              <strong>Reward Points:</strong> {this.state.selectedCustomer.wingo_total_reward_points || 0}
            </MuiTypography>
            <MuiTypography variant="body1">
              <strong>Gift Card Balance:</strong> {this.state.selectedCustomer.gift_card_balance || 0}
            </MuiTypography>
            <MuiButton onClick={this.closeViewModal} color="primary">
              Close
            </MuiButton>
          </div>
        </Modal>}
       
        {this.state.addModalOpen && <div
          data-testid="staff-info"
          className="staff_info__wrapper"
        >
          <AddOrUpdateCustomer
            open={this.state.addModalOpen}
            onClose={!this.state.isEditing ? this.closeAddModal : this.closeEditModal}
            onSubmit={this.addOrUpdateCustomer} 
            initialCustomerData={this.state.isEditing ? this.state.newCustomer : null}
            isEditing={this.state.isEditing}
            validationErrors={this.state.errors}
          />
        </div>}
        {this.state.marketingModalOpen && <div
          data-testid="staff-info"
          className="staff_info__wrapper"
        >
          <CustomerMarketingInfo
            open={this.state.marketingModalOpen}
            onClose={this.closeMarketingInfoModal}
            onSubmit={this.addMarketingInfoModal} // Add or update handler
            initialCustomerData={this.state.newCustomer}
            id={this.state.editingCustomerId}
            isEditing={this.state.isEditing}
            validationErrors={this.state.errors}
          />
        </div>}

        <InfoSuccess
              open={this.state.successPopupOpen}
              onClose={this.handleSuccessPopupClose}
              successMsg={this.state.successText}
            />
      </>
    );
  }
}

// Customizable Area Start

// Customizable Area End
