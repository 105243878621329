import React from "react";
import { success } from "../../SocialLogin/assets";
import Modal from "../../Modal";
import { ContainedButton } from "../../Button";
import "./InfoSuccess.css";
import { IconButton, SvgIcon } from "@mui/material";

interface Props {
  open: boolean;
  onClose: () => void;
  successMsg: string;
}

const InfoSuccess = ({ open, onClose, successMsg }: Props) => {
  return (
    <Modal
       className="Modal"
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      {/* <div className="modal-content"> */}
        {/* <IconButton className='notes_success_cross' onClick={onClose}>
        <SvgIcon>
          <svg
            width="800"
            height="800"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.293 5.293a1 1 0 0 1 1.414 0L12 10.586l5.293-5.293a1 1 0 1 1 1.414 1.414L13.414 12l5.293 5.293a1 1 0 0 1-1.414 1.414L12 13.414l-5.293 5.293a1 1 0 0 1-1.414-1.414L10.586 12 5.293 6.707a1 1 0 0 1 0-1.414"
              fill="#0D0D0D"
            />
          </svg>
        </SvgIcon>
      </IconButton> */}
      <div className="note_success__wrapper">
        <img
          src={success}
          alt="Not Saved Successfully"
          className="note_success__image"
        />
        <p style={{textAlign:"center"}} className="note_success__text">{successMsg}</p>
        <ContainedButton onClick={onClose}>
          BACK TO CUSTOMER MANAGEMENT
        </ContainedButton>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default InfoSuccess;
