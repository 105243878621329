import React from "react";
// Customizable Area Start
import PosOrderCreationController, {
  Order,
  PaginationData,
  Props,
  webConfigJSON
} from "./PosOrderCreationController";
import "./PosOrderCreation.css"
import { IconButton, InputBase, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import SearchRounded from '@material-ui/icons/SearchRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import KeyboardArrowRightRoundedIcon from "@material-ui/icons/KeyboardArrowRightRounded"
import Rolesandpermissions2 from "../../../../rolesandpermissions2/src/Rolesandpermissions2.web";
import Sidebar from "../../../../../components/src/Dashboard/Sidebar/Sidebar";
import Header from "../../../../../components/src/Dashboard/Header/Header";
import { buttonMap, notifyButton, delivery, collection, collectionEnabled, inStoreEnabled, notifyButtonDisable } from "../../assets";
import { Pagination } from "@mui/material";
import AddOrUpdateCustomer from "../../../../../components/src/Dashboard/AddOrUpdateCustomer";
import CustomerMarketingInfo from "../../../../../components/src/Dashboard/CustomerMarketingInfo";
import SuccessFailModal from "../../../../../components/src/Modal/SuccessFailModal";
import ViewOrderDetails from '../../../../../components/src/ViewOrderDetails/ViewOrderDetails.web';
import DialogPopupTasks from '../../../../../components/src/DialoguePopupTasks.web';
import { formatDateandTime } from "../../../../../components/src/utils";
import CustomLoader from "../../../../../components/src/CustomLoader.web";
// Customizable Area End
export default class PosOrderCreation extends PosOrderCreationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  customerInformation=()=>{
    const {customer_name,full_phone_number} = this.state.customerData.attributes;
    const {customerAddress} = this.state.customerInformation
    return(
      <div className="customerInformationContainer">
        <div className="CustomerInformationHeading"><div>Customer Information</div> <IconButton className="buttonMapIcon"><img src={buttonMap} alt="buttonMap" /></IconButton> </div>
        <div className="flexColumn24">
        <div className="flexRow24" style={{gap:"16px", alignItems:"last baseline"}}> 
          <div className="flexColumn24" style={{gap:"4px", width:"100%"}}>
            <div style={{color:"#747474"}}>Search By Mobile Number Or Email Address</div>
            <div className="search__wrapper" style={{padding:"0px 0px 0px 12px", border:"1px solid #1d1d1d", fontFamily:"Barmeno", height:"56px"}} >
                        <SearchRounded />
                        <InputBase style={{border:"0px",fontFamily:"Barmeno"}}
                        className={"customerSearch"} name="search_by_number" placeholder='Enter Mobile Number Or Email Adddress' onChange={this.onChangeSearchText}
                        value={this.state.customerSearchInputText}/>
                    </div>
          </div>
          <button style={{color: !this.state.customerSearchInputText?"#909090":"#E11B22", borderColor: !this.state.customerSearchInputText?"#909090":"#E11B22"}}  className="searchButtonCustomer" disabled={!this.state.customerSearchInputText} onClick={this.searchCustomer} >Search</button>
        </div>
        <div className="customerDetailsCard" > 
          <div className="felxRow24" style={{display:"flex",gap:"16px",width:"fit-content", alignSelf:"end"}}>
            <IconButton disabled={this.disableDetailsMarketing()} className="buttonMapIcon" style={{backgroundColor:"#F1F1F1", color:"#A2A2A2"}} onClick={this.openEditModal}> <EditRoundedIcon/> </IconButton>
            <IconButton disabled={this.disableDetailsMarketing()} className="buttonMapIcon"  style={{backgroundColor:"#F1F1F1", color:"#A2A2A2"}} onClick={this.openMarketingInfoModal}> <img src={notifyButtonDisable} alt="buttonMap"/> </IconButton>
          </div>
          <div className="personalInformationContainer">
            <div className="personalDetailKey">Name</div><div className="personalDetailValue">{customer_name}</div>
            </div>
          <div className="personalInformationContainer">
            <div className="personalDetailKey">Mobile Number</div><div className="personalDetailValue">{full_phone_number}</div>
            </div>
          <div className="personalInformationContainer">
            <div className="personalDetailKey">Address</div><div className="personalDetailValue">{customerAddress}</div>
            </div>
        </div>
        </div>
      </div>
    )
  }

  customerOrderTable=(previous_order:Order[],pagination:PaginationData)=>{
    return(
      <TableContainer style={{fontFamily:"Barmeno",height:"191px",overflow:"scroll"}} >
            <Table style={{ minWidth: "428px", maxWidth:"528px", borderSpacing: '0px 0px', borderCollapse: "separate", width: "100%" }} size="medium">
      <TableHead>
      <TableRow>
        {["","Date","Status","Amount",""].map((headCell, index) => (
          <TableCell
          className={`tableHeaderCells tableFont tableHeader${headCell}${index}`}
            key={index}
            align={"center"}
          >
            {headCell}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
      <TableBody>
        {previous_order.map((row) => (
          <TableRow
            hover
            tabIndex={-1}
            key={row.id}
            style={{ cursor: "auto", height:"50px" }}
            className="tableBodyCustomer"
          >
            <TableCell className="table_cell_posOrderHistory" id="tableInputRadio" align="center"><input value={row.id} onChange={this.changeReorderIcons} type="radio" checked={this.state.reorderId == row.id} /></TableCell> 
            <TableCell
              align="center"
              className="table_cell_posOrderHistory tableFont"
            >
              {new Date(row.attributes.created_at).toLocaleDateString('en-GB')
  .split('/')
  .reverse()
  .join('/')}
            </TableCell>
            <TableCell className="table_cell_posOrderHistory" align="center"><div className={this.provideClassNameWithStatusType(row.attributes.status)}>{row.attributes.status}</div></TableCell>
            <TableCell className="table_cell_posOrderHistory" align="center">£{row.attributes.total}</TableCell>
            <TableCell className="table_cell_posOrderHistory" id="tableInputRadio"><IconButton className="OpenProductDetailClass" onClick={this.getOrderDetails} id={row.id} ><KeyboardArrowRightRoundedIcon /> </IconButton></TableCell>
          </TableRow>
        )
        )}
      </TableBody>
      </Table>
      <Pagination
          style={{padding:"10px"}}
          hidePrevButton={pagination.prev_page === null}
          hideNextButton={pagination.next_page === null}
          count={Math.ceil(pagination.total_count/5)}
          page={this.state.page}
          onChange={this.handlePageChange}
        />
          </TableContainer>
    )
  }

  customerPreviousOrder=()=>{
    return(
      <div className="previousOrderContainer">
        <div className="flexColumn24">
          <div className="headingStyle" style={{color:"#1D1D1D"}}>
          Previous Orders
          </div>
          {this.state.customerData.previous_order[0].length>0?
          this.customerOrderTable(this.state.customerData.previous_order[0],this.state.customerData.previous_order[1])
          :
          <div className="headingStyle" style={{color:"#747474"}}>
          No previous orders
          </div>
          }
        </div>
        <div  className="flexRow24"> 
          <button onClick={this.reorderApiCall} disabled={this.disableDetailsMarketing()||!this.state.reorderId} className={this.disableDetailsMarketing()||!this.state.reorderId?"newOrderButtonDisabled":"repeatOrderButton"}>Repeat Order</button>
          <button disabled={this.disableDetailsMarketing()||!this.state.orderTypeSelection} className={this.disableDetailsMarketing()||!this.state.orderTypeSelection?"newOrderButtonDisabled":"newOrderButton"} onClick={this.createOrderApi} >New Order</button>
        </div>
      </div>
    )
  }

  customerRelatedDetails=()=>{
    return(
      <div className="flexRow24">
        {this.customerInformation()}
      {this.customerPreviousOrder()}
      </div>
    )
  }
  orderTypeSelection=()=>{
    return(
      <div className="flexColumn24">
        <div className="headingStyle" style={{color:"#1D1D1D"}} >Order Type Selection</div>
        <div className="flexRow24" style={{gap:"40px", alignItems:"center", justifyContent:"center"}}>
          {this.orderTypeSelectionArray().map(value=>
            <div accessKey={value.value} key={value.title} className="orderTypeContainer" onClick={this.onChangeOrderType}>
            <div className={value.class}><img src={value.imagePath} alt={value.imagePath} /></div>
            <div className="headingStyle" style={{color: value.class ==="unSelectedOrderType"? "#1D1D1D":"#2C6F37"}}>{value.title}</div>
          </div>
          )}
        </div>
      </div>
    )
  }

  callerIdInformation=()=>{
    return(
      <div className="flexColumn24">
        <div className="headingStyle">Caller ID Information</div>
        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
          {["LINE 1","LINE 2","LINE 3","LINE 4","LINE 5"].map((value)=>
          <div className="phoneLineContainer">{value}</div>
          )}
        </div>
        <div className="flexRow24" style={{gap:"40px", alignItems:"center"}}>
          <div style={{color:"#747474",fontSize:"18px",fontWeight:500}}>Call Status: <span style={{color:"#1D1D1D"}}>{this.state.callStatus}</span></div>
          <div style={{color:"#747474",fontSize:"18px",fontWeight:500}}>Call Started:  <span style={{color:"#1D1D1D"}}>0</span></div>
          <button disabled={this.state.callStatus ==="Down"} className={this.state.callStatus ==="Down" ? "repeatOrderDisableButton":"repeatOrderButton"}>CLEAR PHONE NUMBER</button>
        </div>
      </div>
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div data-testid="dashboard-wrapper" className="dashboard__wrapper">
        <aside data-testid="dashboard-sidebar" className="dashboard__sidebar">
          <Sidebar navigation={this.props.navigation} />
        </aside>

        <main data-testid="dashboard-main" className="dashboard__main">
          <Header navigation={this.props.navigation} headerTitle={"New Order"} />
          <div className="newOrderCreationContainer">
        {this.customerRelatedDetails()}
        {this.orderTypeSelection()}
        {this.callerIdInformation()}
      </div>
        </main>

        <Rolesandpermissions2 id="Popup" navigation={this.props.navigation} />
        <AddOrUpdateCustomer
            open={this.state.editCustomerModal}
            onClose={this.closeEditModal}
            onSubmit={this.addOrUpdateCustomer}
            initialCustomerData={ this.state.newCustomer}
            isEditing={this.state.isEditing}
            validationErrors={this.state.errors}
          />
          <CustomerMarketingInfo
            open={this.state.marketingModalOpen}
            onClose={this.closeMarketingInfoModal}
            onSubmit={this.addMarketingInfoModal}
            initialCustomerData={this.state.newCustomer}
            id={this.state.editingCustomerId}
            isEditing={this.state.isEditing}
            validationErrors={this.state.errors}
          />
          <SuccessFailModal
              open={this.state.successModal}
              onClose={this.openAddModal}
              successMsg={this.state.successMsg}
              img={this.state.img}
              btnText={this.state.btnText}
            />
            <DialogPopupTasks open={this.state.viewOrderDetailsOpen} id={"posdetailPopup"} onClose={this.closeViewOrderDetails}>
                <ViewOrderDetails OrderDetails={this.state.orderDetails} formatDate={formatDateandTime} getInvoiceDataDownload={this.downloadInvoice}/>
            </DialogPopupTasks>
            <CustomLoader loading={this.state.loading}/>
      </div>
     
    );
    // Customizable Area End
  }
}
