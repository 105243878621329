import z from "zod";
import moment from "moment";

const timeSchema = z.object({
  open: z.string(),
  close: z.string(),
}).refine(
  ({ open, close }) => moment(close, ["HH:mm"]).isAfter(moment(open, ["HH:mm"])),
  { message: "Close time must not be before or same open time.", path: ["close"] }
);

export const weekSchema = z.object({
  Monday: timeSchema,
  Tuesday: timeSchema,
  Wednesday: timeSchema,
  Thursday: timeSchema,
  Friday: timeSchema,
  Saturday: timeSchema,
  Sunday: timeSchema,
});
