import React from "react";
import { useReactTable, ColumnDef, getCoreRowModel, flexRender, TableOptions, SortingState, getSortedRowModel } from "@tanstack/react-table";
import { TriangleDownIcon, TriangleUpIcon } from "../../Icons";
import "./DataGrid.css";
import { NoDataFound } from "../../../../blocks/cfposcustomermanagementfe/src/assets";

interface Props<TData, TValue> {
    data: any[];
    columns: ColumnDef<TData, TValue>[];
    tableOptions?: Partial<TableOptions<TData>>;
    emptyRowsText?: string;
}

function DataGrid<TData, TValue> ({ data, columns, tableOptions, emptyRowsText }: Props<TData, TValue>) {
    const rows = React.useMemo(() => data, [data]);
    const columnsMemo = React.useMemo(() => columns, [columns]);
    const [sorting, setSorting] = React.useState<SortingState>([]);

    const table = useReactTable<TData>({
        ...tableOptions,
        data: rows,
        columns: columnsMemo,
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        debugTable: true,
        debugHeaders: true,
        debugColumns: true,
    });

    if (table.getRowModel().rows.length === 0) {
        return (
            <div className="datagrid__empty_rows__wrapper">
                 {emptyRowsText?.includes('No Customer found. Please add a new staff.') && <img
                src={NoDataFound}
                alt="No Data Found"
                width="700"
                height="460"
                className="datagrid__empty_rows__image"
            />}
                <p className="datagrid__empty_rows__text">{emptyRowsText}</p>
            </div>
        )
    }

    return (
        <table data-testid="datagrid" className="datagrid__container">
            <thead className="datagrid__header">
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id} className="datagrid__header__row">
                {headerGroup.headers.map(header => (
                    <th
                    key={header.id}
                    style={{ position: 'relative', width: header.getSize(), backgroundColor: "#F8FAFC" }}
                    className="datagrid__header__cell"
                    >
                    {/* Resizer for columns */}
                    {header.column.getCanResize() && (
                        <div
                        onMouseDown={header.getResizeHandler()}
                        onTouchStart={header.getResizeHandler()}
                        className={`resizer ${header.column.getIsResizing() ? 'isResizing' : ''}`}
                        ></div>
                    )}

                    {/* Sortable header */}
                    {header.column.getCanSort() && (
                        <div
                        className={`${header.column.getCanSort() ? 'cursor-pointer select-none' : ''} datagrid__header_title`}
                        onClick={header.column.getToggleSortingHandler()}
                        title={
                            header.column.getCanSort()
                            ? header.column.getNextSortingOrder() === 'asc'
                                ? 'Sort ascending'
                                : header.column.getNextSortingOrder() === 'desc'
                                ? 'Sort descending'
                                : 'Clear sort'
                            : undefined
                        }
                        >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                            asc: <TriangleUpIcon width={12} height={12} />,
                            desc: <TriangleDownIcon width={12} height={12} />
                        }[header.column.getIsSorted() as "asc" | "desc"] ?? null}
                        </div>
                    )}
                    </th>
                ))}
                </tr>
            ))}
            </thead>

            <tbody className="datagrid__body">
            {table.getRowModel().rows.map(row => (
                <tr key={row.id} className="datagrid__body__row">
                {row.getVisibleCells().map(cell => (
                    <td
                    key={cell.id}
                    style={{ width: cell.column.getSize() }}
                    className="datagrid__body__cell"
                    >
                    {cell.column.id === "phoneNumber" && cell.row._valuesCache.phoneNumber? "+":""}
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                ))}
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default DataGrid;
