import React from "react";
import { IBlock } from "../../../../../framework/src/IBlock";
import { Message } from "../../../../../framework/src/Message";
import { BlockComponent } from "../../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../../framework/src/RunEngine";
import CfposcustomermanagementfeController from "../../../../../blocks/cfposcustomermanagementfe/src/CfposcustomermanagementfeController";


export const configJSON = require("./config");

export interface OrdersPagination{
  total_count:number;
  current_page:number;
  next_page:number|null;
  prev_page:number|null
}

export interface Props {
  navigation: any;
}

interface S {
  token: string;
  data: any;
  addresses:any[];
  activityLog: {
    message: string;
    timestamp: string;
    updatedAttributes: Record<string, any>;
    changedAttributes: any;
    changes: any;
    timeAgo: string;
  }[];
  account_status: boolean;
  openUpdateCustomer: boolean;
  availableIdentityTypes: { id: number; identity_type: string }[];
  availableRoles: { id: number; name: string }[];
  error?: string[];
  validationErrors: Record<string, string>;
  notes: any[];
  openConfirmation: boolean;
  openManageNote: boolean;
  openNoteSuccess: boolean;
  openFilters: boolean;
  activeFilterTab: string;
  editNote: {
    id: number;
    note: string;
  } | null;
  expiryPopupAnchorEl: HTMLElement | null;
  searchQuery: string | null;
  orderTypeFilters: Record<string, boolean>;
  paymentTypeFilters: Record<string, boolean>;

  orderStatusFilters: Record<string, boolean>;

  filteredOrders: any[];
  customerOrders: any;
  addModalOpen:boolean;
  newCustomer: { // Stores the form data for the new customer
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    date_of_birth: string;
    gender:string;
    address: {

      city:string;
      country: string;
      address: string;
      postCode: string;
      addressDirection: string;
      defaultAddress: boolean;
    };
    email_communication?: boolean,
    mobile_communication?: boolean,
  };
  errors: Partial<{
    firstName: string;
    lastName: string;
    email: string;
    countryCode: string;
    phoneNumber: string;
    date_of_birth: string;
    address: string;
    postCode: string;
  }>;
  isEditing:boolean;
  editingCustomerId:string;
successPopupOpen: boolean; 
successText: string;
loading:boolean;
page:number|string;
orders_pagination:OrdersPagination;
}

interface SS {}

export default class ViewCustomerController extends BlockComponent<
  Props,
  S,
  SS> {
  customerDetailsCallId: string = "";
  accountStatusCallId: string = "";
  toggleStatusCallId: string = "";
  activityLogCallId: string = "";
  updateCustomerCallId: string = "";
  availableIdentityCallId: string = "";
  availableRolesCallId: string = "";
  notesCallId: string = "";
  addNoteCallId: string = "";
  updateNoteCallId: string = "";

  constructor(props: Props) {
    super(props);

    this.receive = this.receive.bind(this);
    this.controller.openEditModal = this.controller.openEditModal.bind(this.controller);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      token: "",
      data: {attributes:{first_name:"",last_name:"",email:"",phone_number:"",DOB:"",Gender:""}},
      addresses:[],
      activityLog: [],
      account_status: false,
      openUpdateCustomer: false,
      availableIdentityTypes: [],
      availableRoles: [],
      validationErrors: {},
      notes: [],
      openConfirmation: false,
      openManageNote: false,
      openNoteSuccess: false,
      openFilters: false,
      activeFilterTab:'all',
      editNote: null,
      expiryPopupAnchorEl: null,
      searchQuery: '',
      orderTypeFilters: {
        "collection": false,
        "delivery": false,
        "telephone": false,
      },
      paymentTypeFilters: {
        "Credit Card": false,
        Cash: false,
        "Google Pay": false,
        "Apple Pay": false,
      },
      orderStatusFilters: {
        confirmed: false,
        cooking: false,
        in_transit: false,
        placed: false,
        ready: false,
        cancelled: false,
        cancelled_and_refunded: false,
        collected: false,
        delivered: false,
        payment_failed: false,
        payment_pending: false,
        returned: false,
      },
      filteredOrders: [],
      customerOrders:{orders:[]},
      addModalOpen:false,
      newCustomer: {
        firstName: "",
        lastName: "",
        email: "",
        countryCode:'44',
        phoneNumber: "",
        date_of_birth: "",
        gender:"",
        address: {
          city: "",
          country: "",
          address: "",
          postCode: "",
          addressDirection: "",
          defaultAddress: false,
        },
      },
      isEditing:false,
      editingCustomerId:"",
      errors:{},
      successPopupOpen: false, 
      successText: "",
      loading:false,
      page:1,
      orders_pagination:{
        total_count:0,
        current_page:1,
        prev_page:null,
        next_page:null,
      }
    };

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    await this.getToken();
    this.getCustomerDetails();
  }
  controller = new CfposcustomermanagementfeController(this.props);
  componentDidUpdate(prevProps: Props, prevState: S) {
    if (
      prevState.customerOrders.orders !== this.state.customerOrders.orders &&
      this.state.customerOrders.orders.length > 0
    ) {
      const filtered = this.state.customerOrders.orders.map(
        (order: any) => order.attributes
      );
      this.setState({ filteredOrders: filtered });
    }
    if(prevState.page !== this.state.page){
      this.getCustomerDetails();
    }
  }
  
  
  getMessageHandler(callId: string, apiResponse: any) {
    const messageHandlers = {
      [this.customerDetailsCallId]: () => this.handleCustomerDetails(apiResponse),
      [this.updateCustomerCallId]: () => this.handleUpdateCustomer(apiResponse),
    };
  
    if (!messageHandlers[callId]) {
      return null;
    }
  
    return messageHandlers[callId];
  }
  

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    const apiResponse = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const callId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
    const handler = this.getMessageHandler(callId, apiResponse);
    if (handler) handler();
    if (errorMessage) runEngine.debugLog("API Error", errorMessage);
  }

  async getToken() {
    const token = localStorage.getItem("authToken");
    if (token) this.setState({ token });
  }


  handleUpdateCustomer(data: any) {
    if (data.errors) {
      this.setState({ error: data.errors });
      return;
    }else{
      const customer = data.customer;
      const addreses = customer.addresses
  
      const {orders} = customer;
      
  
      this.setState({ data: customer, customerOrders: {orders: [...orders]}, addresses:addreses,loading:false });
      if(this.state.customerOrders.length){
        this.setState({
          filteredOrders: this.state.customerOrders?.orders?.map((order: any) => order?.attributes)
        });
      }
      this.handleUpdateCustomerClose();
    }
    
    // this.getCustomerDetails();
  }


handleSearch = (event: any) => {
  const query = event.target.value.toLowerCase();
  const filtered = this.state.customerOrders?.orders
    .map((order: any) => order.attributes)
    .filter(
      (order: any) =>
        order.order_number.toLowerCase().includes(query) 
    ) || [];

  this.setState({ searchQuery: query, filteredOrders: filtered });
};


handleFilterChange = (filterCategory: keyof S, filterName: string) => {
  this.setState((prevState) => ({
    ...prevState,
    [filterCategory]: {
      ...(prevState[filterCategory] as Record<string, boolean>),
      [filterName]: !(
        prevState[filterCategory] as Record<string, boolean>
      )[filterName],
    },
  }));
};

handleFilterChangeAgain = (filterCategory: keyof S, filterName: string,value:boolean) => {
  this.setState((prevState) => ({
    ...prevState,
    [filterCategory]: {
      ...(prevState[filterCategory] as Record<string, boolean>),
      [filterName]: value
    },
  }));
};
handleOrderStatus=(value:string)=>{
  if(value == "active"){
    this.activeOrderStatus.forEach((status) =>
      this.handleFilterChangeAgain("orderStatusFilters", status.value,true)
    );
    this.pastOrderStatus.forEach((status) =>
      this.handleFilterChangeAgain("orderStatusFilters", status.value,false)
    );
  }else if(value == "past"){
    this.pastOrderStatus.forEach((status) =>
      this.handleFilterChangeAgain("orderStatusFilters", status.value,true)
    );
    this.activeOrderStatus.forEach((status) =>
      this.handleFilterChangeAgain("orderStatusFilters", status.value,false)
    );
  } else{
    this.handleFiltersReset()
  }
  this.setState({ activeFilterTab: value})
}

handleFiltersApply = () => {
  const { customerOrders, orderTypeFilters, paymentTypeFilters, orderStatusFilters } = this.state;

  const filteredOrders = customerOrders?.orders
    .map((order: any) => order.attributes)
    .filter((order: any) => {
      const orderTypeMatch =
        Object.keys(orderTypeFilters).every((type) => !orderTypeFilters[type]) ||
        orderTypeFilters[order.order_type];
      const paymentTypeMatch =
        Object.keys(paymentTypeFilters).every((type) => !paymentTypeFilters[type]) ||
        paymentTypeFilters[order.source];
      const orderStatusMatch =
        Object.keys(orderStatusFilters).every((status) => !orderStatusFilters[status]) ||
        orderStatusFilters[order.status];
      return orderTypeMatch && paymentTypeMatch && orderStatusMatch;
    });

  this.setState({ filteredOrders, openFilters: false });
};


handleFiltersReset = () => {
  this.setState({
    orderTypeFilters: {
      Collection: false,
      Delivery: false,
      Telephone: false,
    },
    paymentTypeFilters: {
      "Credit Card": false,
      Cash: false,
      "Google Pay": false,
      "Apple Pay": false,
    },
    orderStatusFilters: {
      confirmed: false,
      cooking: false,
      in_transit: false,
      placed: false,
      ready: false,
      cancelled: false,
      cancelled_and_refunded: false,
      collected: false,
      delivered: false,
      payment_failed: false,
      payment_pending: false,
      returned: false,
    },
    filteredOrders: this.state.customerOrders?.orders.map((order: any) => order.attributes),
  });
};


  handleCustomerDetails = (apiResponse: any) => {
    if (apiResponse.customer) {
      const customer = apiResponse.customer;
      const addreses = customer.addresses
      const orders_pagination = customer.orders_pagination

      const {orders} = customer;
      

      this.setState({ data: customer, customerOrders: {orders: [...orders]}, addresses:addreses,loading:false,orders_pagination});
      if(this.state.customerOrders.length){
        this.setState({
          filteredOrders: this.state.customerOrders?.orders?.map((order: any) => order?.attributes)
        });
      }
      
    }
  };


  getCustomerDetails = () => {
    const id = this.props.navigation?.getParam("customerId");
   this.setState({loading:true})
  
    const getDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.customerDetailsCallId = getDataMsg.messageId; 
  
    getDataMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.customerInformationAPI.endPoint + `/${id}?page=${this.state.page}`
    );
    getDataMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.contentType,
        token: this.state.token,
      })
    );
    getDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.customerInformationAPI.method);
    
    runEngine.sendMessage(getDataMsg.id, getDataMsg);
  };

  updateCustomer = (data: any) => {
    const id = this.props.navigation?.getParam("id");
    const formData = this.createFormData(data);

    const postDataMsg = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.updateCustomerCallId = postDataMsg.messageId;
    postDataMsg.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.updateCustomerAPI.endPoint + `/${id}`);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), {
      token: this.state.token,
    });
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    postDataMsg.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.updateCustomerAPI.method);
    runEngine.sendMessage(postDataMsg.id, postDataMsg);

    return { success: true };
  };

  
  createFormData = (data: any) => {
    const formData = new FormData();
    formData.append(
      "account[customer_id]",
      data.customerId
    );
    data.roles.forEach((role: number) => {
      formData.append("account[role_ids][]", role.toString());
    });
    formData.append("account[first_name]", data.firstName);
    formData.append("account[last_name]", data.lastName);
    formData.append("account[country_code]", data.countryCode);
    formData.append("account[phone_number]", data.phoneNumber.replace(data.countryCode, ""));
    formData.append("account[full_phone_number", data.phoneNumber);
    formData.append("account[identity_type]", data.identityType);

    if (data.identityDocument.length > 0 && data.identityDocument[0] instanceof File)
      formData.append("account[identity_document]", data.identityDocument[0]);

    formData.append("account[permit_require]", JSON.stringify(data.permitRequired));
    formData.append("account[post_code]", data.postCode);
    if (data.permitRequired) {
      if (data.visaDocument.length > 0 && data.visaDocument[0] instanceof File)
        formData.append("account[visa]", data.visaDocument[0]);
      formData.append("account[expiry_date]", data.expiryDate);
    }
    formData.append("account[email]", data.email);
    formData.append("account[password]", data.password);

    return formData;
  };

  handleUpdateCustomerOpen = () => {
    this.setState({ openUpdateCustomer: true });
  };

  handleFiltersOpen = () => {
    this.setState({ openFilters: true });
  };



  handleFiltersClose = () => {
    this.setState({ openFilters: false });
  };



  handleUpdateCustomerClose = () => {
    this.setState({ openUpdateCustomer: false, error: undefined, validationErrors: {} });
  };

  confirmClose = () => {
    this.setState({ openConfirmation: true });
  }

  handleConfirmationClose = () => {
    this.setState({ openConfirmation: false });
  }


  discardChanges = () => {
    this.handleUpdateCustomerClose();
    this.handleConfirmationClose();
  }


  formatDateTime(dateTimeStr: string): string {
    const date = new Date(dateTimeStr);

    const months = [
      "January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"
    ];

    const month = months[date.getMonth()];
    const day = date.getDate();
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; 

    const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;

    return `${month} ${day}, ${year} at ${hours}:${formattedMinutes} ${ampm}`;
  }

  openExpiryPopup = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ expiryPopupAnchorEl: event.currentTarget });
  };

  closeExpiryPopup = () => {
    this.setState({ expiryPopupAnchorEl: null });
  }

  handleEditAction = (customerDetail:any) => {
    const customer = customerDetail.attributes
    const address = customerDetail.addresses[0]?.attributes ||
    {address: "",
      postCode:"",
      city: "",
      country: "",
      addressDirection:  "",
      defaultAddress:  false}

      this.setState({
        addModalOpen: true,
        isEditing: true,
        editingCustomerId: customer.id,
        newCustomer: {
          firstName: customer.first_name || "",
          lastName: customer.last_name || "",
          email: customer.email || "",
          countryCode: customer.countryCode||'44',
          phoneNumber: customer.phone_number || 0,
          date_of_birth: customer.date_of_birth ||"",
          gender:customer.gender||"",
          address: {
            address:  address.address,
            postCode: address.post_code || "",
            city: address.city || "",
            country: address.country || "",
            addressDirection: customer.address ||  "",
            defaultAddress:  address.default_address,
          },
        },
        errors: {},
      })
  };
  closeEditModal = () => {
    this.setState({
      addModalOpen: false,
      isEditing: false,
      editingCustomerId: "",
      newCustomer: {
        firstName: "",
        lastName: "",
        email: "",
        countryCode:'44',
        phoneNumber: "",
        gender:"",
        
        date_of_birth: "",
        address: {
          city: "",
          country: "",
          address: "",
          postCode: "",
          addressDirection: "",
          defaultAddress: false,
        },
      },
      errors: {},
    });
  };

  addOrUpdateCustomer = (formdata: any) => {
    this.setState({
      newCustomer: {
        firstName: formdata.firstName,
        lastName: formdata.lastName,
        gender:formdata.gender,
        email: formdata.email,
        countryCode: formdata.countryCode,
        phoneNumber: formdata.phoneNumber,
        date_of_birth: formdata.date_of_birth,
        address: {
          address:  formdata.address.addressDirection,
          postCode: formdata.address.postCode,
          city: "ghaziabad",
          country: "UK",
          addressDirection:  formdata.address.addressDirection,
          defaultAddress:  true,
        },
      },
    });
    const isValid = this.validateCustomerForm({
      firstName: formdata.firstName,
      lastName: formdata.lastName,

      email: formdata.email,
      countryCode: formdata.countryCode,
      phoneNumber: formdata.phoneNumber,
      date_of_birth: formdata.date_of_birth,
      address: {
        address:  formdata.address.addressDirection,
        postCode: formdata.address.postCode,
        city: "ghaziabad",
        country: "UK",
        addressDirection:  formdata.address.addressDirection,
        defaultAddress:  true,
      },
    });
  
    if (!isValid) {
      console.error("Form validation failed.");
      return;
    }
  
    const { token, isEditing, editingCustomerId } = this.state;
    if (!token) {
      console.error("Token is missing. Unable to add or update customer.");
      this.setState({ error: ["Unable to proceed. Please log in again."] });
      return;
    }
  
    const customerData =  {
        data: {
          attributes: {
            first_name: formdata.firstName,
            last_name: formdata.lastName,
            email: formdata.email,
            gender:formdata.gender,
            full_phone_number: formdata.phoneNumber,
            name: '',
            date_of_birth: formdata.date_of_birth,
            address_type: "",
            address: formdata.address.addressDirection,
            default_address: formdata.default_address,
            post_code: formdata.address.postCode,
            country: "",
            city: "",
            // latitude: 28.5491844,
            // longitude: 77.3460833,
            address_direction: formdata.address.addressDirection,

          },
        },
      };
  
    const message = new Message(getName(MessageEnum.RestAPIRequestMessage));
    const callId = message.messageId;
  
    const endpoint = isEditing
      ? `${configJSON.customerList.endPoint}/${editingCustomerId}`
      : configJSON.customerList.endPoint;
  
    const method ="PUT";
  
    message.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), endpoint);
    message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": "application/json",
        token: token,
      })
    );
    message.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(customerData));
    message.addData(getName(MessageEnum.RestAPIRequestMethodMessage), method);
  
    runEngine.sendMessage(message.id, message);
  
    const handleResponse = (response: any) => {
      if(response.errors){
        const errors: Record<string, string> = {};
        if(response.errors[0].account){
        if (response.errors[0].account.includes("Email")) 
          errors.email = response.errors[0].account;
        } else{
        if (response.errors[0].includes("Phone")) errors.phoneNumber = response.errors[0];
        }

        this.setState({ errors });
      }
      else if (!response.errors) {
      const customer = response.customer;
      const addreses = customer.addresses
  
      const {orders} = customer;
      this.setState({ data: {...this.state.data,attributes:customer},loading:false, addModalOpen: false,
        isEditing: false });
      if(orders){
        this.setState({
          filteredOrders: this.state.customerOrders?.orders?.map((order: any) => order?.attributes),customerOrders: {orders: [...orders]}
        });
      }
          this.handleSuccessPopupOpen('Customer Information has been updated successfully!')
      } else {
        this.setState({
          error: [`Failed to update customer.`],
        });
      }
    };
  
    const messageHandlers: Record<string, (response: any) => void> = {
      [callId]: handleResponse,
    };
  
    const handleError = (errorMessage: any) => {
      console.error("API Error:", errorMessage);
      this.setState({ error: errorMessage });
    };

    this.receive = async (from: string, message: Message) => {
      const receivedCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
      const response = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      const errorMessage = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
  
      const handler = messageHandlers[receivedCallId];
      if (handler) handler(response);

      if (receivedCallId === callId) {
        if (response) {
          handleResponse(response);
        } else if (errorMessage) {
          handleError(errorMessage);
        }
      }
  
      if (errorMessage) {
        console.error("API Error:", errorMessage);
        const errors: Record<string, string> = {};
        const apiErrors = errorMessage.errors || [];
  
        apiErrors.forEach((err: string) => {
          if (err.includes("First name")) errors.firstName = err;
          if (err.includes("Last name")) errors.lastName = err;
          if (err.includes("Email")) errors.email = err;
          if (err.includes("Phone number")) errors.phoneNumber = err;
          if (err.includes("Address")) errors.address = err;
          if (err.includes("Post code")) errors.postCode = err;
          if (err.includes("DOB")) errors.date_of_birth = err;
        });
  
        this.setState({ errors });
      }
    };
  };

  validateCustomerForm = (newCustomer: any) => {
    const errors: Record<string, string> = {};

    if (!newCustomer.firstName.trim()) errors.firstName = "This field should not be left empty";
    if (!newCustomer.lastName.trim()) errors.lastName = "This field should not be left empty";
    if (/\s/.test(newCustomer.lastName)) {
      errors.lastName = "First name should not contain spaces between characters.";
    }
    if (!newCustomer.email.trim()) errors.email = "This field should not be left empty";
    else if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(newCustomer.email)) errors.email = "The email address you entered is not in a valid format. Please enter a valid email address";
    if (!newCustomer.phoneNumber) errors.phoneNumber = "This field should not be left empty";
    else if (newCustomer.phoneNumber.length < 9 || newCustomer.phoneNumber.length > 12) {
      errors.phoneNumber = "The mobile number you entered is not in a valid format. Please enter a valid mobile number";
    }
    if (!newCustomer.address.address.trim()) errors.address = "This field should not be left empty";
    if (newCustomer.address.address.trim().length > 100) errors.addressDirection = "This field should not exceed 100 characters. Please adjust the input.";
    if (!newCustomer.address.postCode.trim()) errors.postCode ="This field should not be left empty";
    if (new Date(newCustomer.date_of_birth) > new Date()) {
      errors.date_of_birth = "Date of birth cannot be in the future.";
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0; // Return true if no errors
  };
  handlePageChange=(event: React.ChangeEvent<unknown>,page:number)=>{
    this.setState({page})
  }
  handleSuccessPopupClose = () => this.setState({ successPopupOpen: false , successText:''});
  handleSuccessPopupOpen = (successTxt: string) => this.setState({ successPopupOpen: true, successText: successTxt});
  activeOrderStatus=[{value:"placed",label:"Order Placed"},{value:"confirmed",label:"Order Accepted"},{value:"cooking",label:"Cooking"},{value:"ready",label:"Ready"},{value:"in_transit",label:"Picked Up"}]
  pastOrderStatus=[{value:"delivered",label:"Delivered"},{value:"collected",label:"Collected"},{value:"cancelled",label:"Cancelled"},{value:"payment_failed",label:"Payment Failed"},{value:"returned",label:"Returned"},{value:"payment_pending",label:"Payment Pending"},{value:"cancelled_and_refunded",label:"Cancelled and Refunded"}]
  paymentFilterStatus=[{value:"card",label:"Card"},{value:"pay on cash",label:"Cash"},{value:"gpay",label:"Google Pay"},{value:"apple pay",label:"Apple Pay"}]
  orderTypeFilter=[{value:"collection",label:"Collection"},{value:"delivery",label:"Delivery"},{value:"telephone",label:"Telephone"},{value:"in_store",label:"In Store"}]
  activeInActiveFilter=[{value:"all",label:"All"},{value:"active",label:"Active Orders"},{value:"past",label:"Past Orders"}]
}
