Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.putApiMethod = "PUT"
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "cfposordermanagementfe";
exports.labelBodyText = "cfposordermanagementfe Body";

exports.btnExampleTitle = "CLICK ME";
exports.menuDataEndPoint="bx_block_catalogue/catalogues"
exports.dealsDataEndPoint="bx_block_catalogue/deals_list"
exports.allergensListApiEndPoint = "bx_block_catalogue/show_all_allergens"
exports.getSpiceLevelApiEndPoint = 'bx_block_catalogue/show_all_spice_level'
exports.getSidesApiEndPoint = "bx_block_order_management/orders/show_sides?restaurant_id="
exports.getCustomProductsApiEndPoint = "bx_block_order_management/orders/show_custom_product?restaurant_id="
exports.addItemsToCartApiEndPoint = "bx_block_order_management/pos_orders/create_order_by_pos_user"
exports.getAllCartItemsApiEndPoint = "bx_block_order_management/pos_orders/cart_details?account_id="
exports.updateItemsApiEndPoint = "bx_block_shopping_cart/carts/"
exports.apiMethodTypeDelete = "DELETE"
exports.cancelOrderApiEndPoint = "order_management/orders/"
// Customizable Area End