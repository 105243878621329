import React from "react";
import { success,failure } from "../SocialLogin/assets";
import Modal from "../Modal";
import { ContainedButton } from "../Button";
import "../Dashboard/CustomerMarketingInfo/InfoSuccess.css";

interface Props {
  open: boolean;
  onClose: () => void;
  successMsg: string;
  img:string;
  btnText: string;
}

const SuccessFailModal = ({ open, onClose, successMsg,img,btnText }: Props) => {
  return (
    <Modal
       className="Modal"
      maxWidth="sm"
      open={open}
      onClose={onClose}
    >
      <div className="note_success__wrapper">
        <img
          src={img==="success"?success:failure}
          alt="Not Saved Successfully"
          className="note_success__image"
        />
        <p style={{textAlign:"center"}} className="note_success__text">{successMsg}</p>
        <ContainedButton onClick={onClose}>
          {btnText}
        </ContainedButton>
      </div>
      {/* </div> */}
    </Modal>
  );
};

export default SuccessFailModal;
