import React from "react";

import { Input, Button, Typography } from '@builder/component-library';

// Customizable Area Start
import { AddRounded, CloseRounded, SearchRounded } from "@material-ui/icons";
import { Drawer, FormControl, FormControlLabel, FormHelperText, IconButton, InputAdornment, Popover, Tab, Tabs } from "@mui/material";
import { BaseInput, UnstyledInput, WingoInput, WingoTimeInput } from "../../../components/src/Inputs/CustomInputs";
import { ChevronLeft, ChevronRight, ClockIcon, InfoIcon, PrintIcon } from "../../../components/src/Icons";
import { ContainedButton, OutlinedButton, UnStyledButton } from "../../../components/src/Button";
import { StoreHolidayColumns } from "../../../components/src/Dashboard/DataGrid/columns/StoreHolidaysColumns";
import { UnstyledIconButton } from "../../../components/src/IconButton";
import { WingosSwitch } from "../../../components/src/Switch";
import DashboardWrapper from "../../../components/src/Dashboard/DashboardWrapper";
import Header from "../../../components/src/Dashboard/Header/Header";
import Select from "../../../components/src/Select";
import FormLabel from "../../../components/src/FormLabel";
import SuccessPopup from "../../../components/src/Dashboard/DeliveryAreas/SuccessPopup";
import ActionCell from "../../../components/src/Dashboard/Settings/ActionCell";
import DataGrid from "../../../components/src/Dashboard/DataGrid";
import ErrorPopup from "../../../components/src/ErrorPopup";
import Modal from "../../../components/src/Modal";
import DateInput from "../../../components/src/DateTimePicker";
import PhoneInputComponent from "../../../components/src/PhoneInput";
import "./Cfpossettings1.web.css";
// Customizable Area End

import Cfpossettings1Controller, {
  Props,
  configJSON,
} from "./Cfpossettings1Controller";
import { deleteImage } from "./assets";
import moment from "moment-timezone";

export default class Cfpossettings1 extends Cfpossettings1Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  previousYears = Array.from({ length: 10 }, (_, i) => this.state.selectedYear - (i + 1)).reverse();
  nextYears = Array.from({ length: 10 }, (_, i) => this.state.selectedYear + (i + 1));
  yearOptions = [...this.previousYears, this.state.selectedYear, ...this.nextYears].map(year => year.toString());
  searchRef = React.createRef<HTMLInputElement>();
  columns = StoreHolidayColumns({
    actionCell: (info) => ActionCell(info, this.confirmDeletePopupOpen)
  });

  handleFocus = () => {
    this.searchRef.current?.focus();
  };

  renderSearch = () => {
    return (
      <div
        data-testid="search"
        className="cf_settings__search"
        onClick={this.handleFocus}
      >
        <SearchRounded fontSize="small" />
        <BaseInput
          inputRef={this.searchRef}
          placeholder="Search here"
          value={this.state.search}
          onChange={this.handleSearchInput}
        />
      </div>
    );
  };

  renderTabs = () => {
    const { selectedTab } = this.state;
    const icons: Record<string, JSX.Element> = {
      "General Settings": <ClockIcon />,
      "Print Settings": <PrintIcon />
    };
    const tabs = configJSON.tabs.map((tab: { id: number; title: string; }) => ({
      ...tab,
      icon: icons[tab.title] ?? undefined
    }));

    return (
      <Tabs
        data-testid="tabs"
        variant="fullWidth"
        value={selectedTab}
        onChange={this.setSelectedTab}
        classes={{
          root: "cf_settings__tabs",
          indicator: "cf_settings__tab__indicator",
        }}
      >
        {tabs.map((tab: { id: number; title: string; icon?: JSX.Element }) => (
          <Tab
            icon={tab.icon}
            label={tab.title}
            classes={{
              root: "cf_settings__tab",
              selected: "cf_settings__tab__selected",
            }}
          />
        ))}
      </Tabs>
    );
  };

  renderStoreHours = () => {
    return (

      <div className="cf_settings__section">
        <div className="cf_settings__section__header">
          <h3>Store Hours</h3>
        </div>

        <div className="cf_settings__time_settings">
          <div className="cf_settings__formats">
            <FormControl>
              <FormLabel>Time Zone</FormLabel>
              <Select
                options={this.timeZoneOptions}
                selectProps={{
                  name: "time_zone",
                  value: this.state.time_zone,
                  onChange: this.handleChange
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Time Format</FormLabel>
              <Select
                options={this.timeFormatOptions}
                selectProps={{
                  name: "time_format",
                  value: this.state.time_format,
                  onChange: this.handleChange
                }}
              />
            </FormControl>
          </div>

          {this.weekdays.map(weekday => {
            const item = this.state.storeHours?.[weekday as string];
            const label = weekday.charAt(0).toUpperCase() + weekday.slice(1);
            const open = moment(item?.open, ["HH:mm"]).format(this.state.time_format === "24-hour" ? "HH:mm" : "hh:mm A");
            const close = moment(item?.close, ["HH:mm"]).format(this.state.time_format === "24-hour" ? "HH:mm" : "hh:mm A");

            return (
              <div key={weekday} data-testid="store-hours" className="cf_settings__store_hours">
                <FormLabel>{label}</FormLabel>

                <div className="cf_settings__day_hours">
                  <FormControl className="cf_settings__form_control">
                    <FormLabel>From</FormLabel>
                    <Select
                      options={this.state.timeOptions}
                      selectProps={{
                        name: `${weekday}+open`,
                        value: open ?? "",
                        onChange: this.handleStoreHoursChange
                      }}
                    />
                    <FormHelperText error>
                      {this.state.saveSettingsError.find(value => value.path.includes(weekday) && value.path.includes("open"))?.message ?? " "}
                    </FormHelperText>
                  </FormControl>

                  <FormControl className="cf_settings__form_control">
                    <FormLabel>To</FormLabel>
                    <Select
                      options={this.state.timeOptions}
                      selectProps={{
                        name: `${weekday}+close`,
                        value: close ?? "",
                        onChange: this.handleStoreHoursChange
                      }}
                    />
                    <FormHelperText error>
                      {this.state.saveSettingsError.find(value => value.path.includes(weekday) && value.path.includes("close"))?.message ?? " "}
                    </FormHelperText>
                  </FormControl>
                </div>
              </div>
            )}
          )}
        </div>
      </div>
    );
  };

  renderDrawer = () => {
    const { openDrawer } = this.state;

    return (
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={this.handleDrawerClose}
        classes={{
          paper: "cf_settings__drawer__wrapper"
        }}
      >
        <UnstyledIconButton onClick={this.handleDrawerClose}>
          <ChevronRight />
        </UnstyledIconButton>
        {this.renderHolidaysList()}
      </Drawer>
    );
  };

  renderHolidaysList = () => {
    return (
      <div className="cf_settings__section">
        <div className="cf_settings__section__header">
          <div className="cf_settings__holiday__header">
            <h3>Upcoming Holidays</h3>
            <Select
              options={this.yearOptions}
              selectProps={{
                value: this.state.selectedYear,
                onChange: this.handleHolidayFilterChange,
                input: <UnstyledInput />,
                sx: {
                  minWidth: 96
                }
              }}
            />
          </div>
          <button onClick={this.handleOpenAddHolidayModal}>
            <AddRounded />
            <span>Add</span>
          </button>
        </div>

        {this.state.holidays.length > 0 ? (
          <DataGrid
            data={this.state.holidays}
            columns={this.columns}
          />
        ) : (
          <span className="cf_settings__placeholder">{configJSON.noHolidaysMessage}</span>
        )}
      </div>
    );
  };

  addHolidayDialog = () => {
    const open = this.state.addHolidayModal.open;
    const data = this.state.addHolidayModal.data;

    return (
      <Modal maxWidth="md" open={open} onClose={this.handleCloseAddHolidayModal}>
        <div className="cf_settings__add_holiday__wrapper">
          <div className="cf_settings__add_holiday__header">
            <h4>Add Holiday</h4>
            <IconButton onClick={this.handleCloseAddHolidayModal}>
              <CloseRounded />
            </IconButton>
          </div>

          <div className="cf_settings__add_holiday__form">
            <FormControl required>
              <FormLabel>Date</FormLabel>
              <DateInput
                minDate={moment().toDate()}
                value={data?.date}
                placeholderText="YYYY-MM-DD"
                onChange={this.handleDateChange}
              />
              <FormHelperText error={!!this.state.createHolidayErrors.find(value => value.field === "date")}>
                {this.state.createHolidayErrors.find(value => value.field === "date")?.message}
              </FormHelperText>
            </FormControl>

            <FormControl required>
              <FormLabel>Reason</FormLabel>
              <WingoInput
                multiline
                minRows={4}
                name="reason"
                placeholder="Please enter the reason for the holiday"
                value={data?.reason}
                onChange={this.handleInputChange}
                error={!!this.state.createHolidayErrors.find(value => value.field === "reason")}
              />
              <FormHelperText
                classes={{ root: "cf_settings__add_holiday__form__textarea_helper" }}
                error={!!this.state.createHolidayErrors.find(value => value.field === "reason") || (data?.reason?.length ?? 0) > 20}
              >
                <span>{this.state.createHolidayErrors.find(value => value.field === "reason")?.message}</span>
                <span>{data?.reason?.length}/20</span>
              </FormHelperText>
            </FormControl>
          </div>

          <div className="cf_settings__add_holiday__footer">
            <OutlinedButton onClick={this.handleCloseAddHolidayModal}>CANCEL</OutlinedButton>
            <ContainedButton onClick={this.addHoliday}>ADD</ContainedButton>
          </div>
        </div>
      </Modal>
    )
  };

  confirmDeleteDialog = () => {
    const open = this.state.confirmDeletePopup.open;
    return (
      <Modal maxWidth="sm" open={open} onClose={this.confirmDeletePopupClose}>
        <div className="cf_settings__confirm_delete__wrapper">
          <img src={deleteImage} alt="delete" />
          <h4>Delete the Store holiday</h4>
          <p>Are you sure you want to delete this holiday?</p>
          <div className="cf_settings__confirm_delete__button_group">
            <OutlinedButton onClick={this.confirmDeletePopupClose}>CLOSE</OutlinedButton>
            <ContainedButton onClick={this.deleteHoliday}>DELETE</ContainedButton>
          </div>
        </div>
      </Modal>
    );
  };

  renderPopups = () => {
    return (
      <>
        {this.state.successPopup.open && this.renderSuccessPopup()}
        {this.state.errorPopup.open && this.renderErrorPopup()}
      </>
    );
  };

  renderSuccessPopup = () => {
    return (
      <SuccessPopup
        data-testid="success-popup"
        open={this.state.successPopup.open}
        onClose={this.handleSuccessPopupClose}
        message={this.state.successPopup.message || ""}
      />
    );
  };

  renderErrorPopup = () => {
    return (
      <ErrorPopup
        data-testid="error-popup"
        open={this.state.errorPopup.open}
        onClose={this.handleErrorPopupClose}
        message={this.state.errorPopup.message}
      />
    );
  };

  renderModals = () => {
    return (
      <>
        {this.addHolidayDialog()}
        {this.confirmDeleteDialog()}
      </>
    );
  };

  renderDeliveryType = () => {
    const { collection_time, delivery_time } = this.state;

    return (
      <div className="cf_settings__section">
        <div className="cf_settings__section__header">
          <h3>Order Type</h3>
        </div>

        <div className="cf_settings__fields__grid">
          {this.renderSwitch(
            "Delivery",
            "supports_delivery",
            "If this option is disabled, the user will not be able to receive home delivery."
          )}

          <FormControl
            classes={{
              root: "cf_settings__field"
            }}
          >
            <FormLabel className="cf_settings__field__label">Estimated Delivery Time</FormLabel>
            <Select
              options={this.timeOptions}
              selectProps={{
                name: "delivery_time",
                value: this.convertMinutesToTime(delivery_time),
                onChange: this.handleTimeChange,
                classes: { root: "cf_settings__field__input" },
                input: <WingoTimeInput />,
                IconComponent: (params) => <ClockIcon {...params} className={`${params.className} cf_settings__select__icon`} />,
              }}
            />
          </FormControl>
        </div>

        <div className="cf_settings__fields__grid">
          {this.renderSwitch(
            "Collection",
            "supports_collection",
            "If this option is disabled, the user will not be able to collect up their order."
          )}

          <FormControl
            classes={{
              root: "cf_settings__field"
            }}
          >
            <FormLabel className="cf_settings__field__label">Estimated Collection Time</FormLabel>
            <Select
              options={this.timeOptions}
              selectProps={{
                name: "collection_time",
                value: this.convertMinutesToTime(collection_time),
                onChange: this.handleTimeChange,
                classes: { root: "cf_settings__field__input" },
                input: <WingoTimeInput />,
                IconComponent: (params) => <ClockIcon {...params} className={`${params.className} cf_settings__select__icon`} />,
              }}
            />
          </FormControl>
        </div>
      </div>
    );
  };

  renderPaymentType = () => {
    return (
      <div className="cf_settings__section">
        <div className="cf_settings__section__header">
          <h3>Payment Type</h3>
        </div>

        <div className="cf_settings__fields__grid">
          {this.renderSwitch(
            "Card",
            "accepts_card_payment",
            "If this option is disabled, the user will not be able to pay by card."
          )}

          {this.renderSwitch(
            "Cash",
            "accepts_cash_payment",
            "If this option is disabled, the user will not be able to pay by cash."
          )}
        </div>
      </div>
    );
  };

  renderSwitch = (label: string, name: string, description: string) => {
    const { openDescriptionAnchorEl } = this.state;
    const open = Boolean(openDescriptionAnchorEl[name]);

    return (
      <>
        <FormControlLabel
          name={name}
          label={
            <>
              <span>{label}</span>
              <UnstyledIconButton onClick={(event) => this.handleDescriptionOpen(event, name)}>
                <InfoIcon width={20} height={20} />
              </UnstyledIconButton>
            </>
          }
          control={<WingosSwitch />}
          checked={this.state[name] as boolean}
          onChange={(_event, checked) => this.handleSwitchChange(name, checked)}
          classes={{
            root: "cf_settings__switch__root",
            label: "cf_settings__switch__label"
          }}
        />

        <Popover
          open={open}
          anchorEl={openDescriptionAnchorEl[name]}
          onClose={() => this.handleDescriptionClose(name)}
          classes={{
            paper: "cf_settings__description__wrapper"
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <>
            <div className="cf_settings__description__header">
              <h2>{label}</h2>
              <IconButton onClick={() => this.handleDescriptionClose(name)}>
                <CloseRounded />
              </IconButton>
            </div>
            <p>{description}</p>
          </>
        </Popover>
      </>
    );
  };

  renderContactInformation = () => {
    const { contact_info } = this.state;

    return (
      <div className="cf_settings__section">
        <div className="cf_settings__section__header">
          <h3>Contact Information</h3>
        </div>

        <div className="cf_settings__fields__grid">
          <FormControl>
            <FormLabel data-required={true} className="cf_settings__field__label">Telephone Number</FormLabel>
            <PhoneInputComponent
              inputStyle={{
                fontWeight: 400,
                color: "#1D1D1D",
                fontFamily: "Barmeno",
                width: "300px",
                height: "46px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #1D1D1D",
              }}
              inputProps={{
                name: "primary_phone",
                required: true,
              }}
              country={"gb"}
              value={contact_info.primary_phone}
              onChange={(value, data) => {
                if ("dialCode" in data) {
                  this.setState({ contact_info: { ...contact_info, country_code: data.dialCode, primary_phone: value } });
                }
              }}
            />
          </FormControl>

          <FormControl>
            <FormLabel className="cf_settings__field__label">Telephone Number 2</FormLabel>
            <PhoneInputComponent
              inputStyle={{
                fontWeight: 400,
                color: "#1D1D1D",
                fontFamily: "Barmeno",
                width: "300px",
                height: "46px",
                backgroundColor: "#FFFFFF",
                border: "1px solid #1D1D1D",
              }}
              inputProps={{
                name: "secondary_phone",
                required: true,
              }}
              country={"gb"}
              value={contact_info.secondary_phone}
              onChange={(value, data) => {
                if ("dialCode" in data) {
                  this.setState({ contact_info: { ...contact_info, secondary_phone_country_code: data.dialCode, secondary_phone: value } });
                }
              }}
            />
          </FormControl>
        </div>
      </div>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <DashboardWrapper
        navigation={this.props.navigation}
      >
        <Header
          navigation={this.props.navigation}
          headerTitle={configJSON.title}
          search={this.renderSearch()}
        />

        <div className="cf_settings__wrapper">
          {this.renderTabs()}

          {this.state.selectedTab === 0 && (
            <>
              <div className="cf_settings__content">
                <div className="cf_settings__content__header">
                  <h2>
                    {configJSON.tabs.find((tab: { id: number; title: string; }) => tab.id === this.state.selectedTab).title}
                  </h2>

                  <UnStyledButton startIcon={<ChevronLeft />} onClick={this.handleDrawerOpen} className="cf_settings__drawer__trigger">
                    View Holidays
                  </UnStyledButton>
                </div>

                <div className="cf_settings__sections__wrapper">
                  {this.renderStoreHours()}
                  <div className="cf_settings__holidays_wrapper">
                    {this.renderHolidaysList()}
                  </div>
                </div>

                <div className="cf_settings__sections__wrapper">
                  {this.renderDeliveryType()}
                </div>

                <div className="cf_settings__sections__wrapper">
                  {this.renderPaymentType()}
                </div>

                <div className="cf_settings__sections__wrapper">
                  {this.renderContactInformation()}
                </div>
              </div>

              <div className="cf_settings__button_group">
                <OutlinedButton onClick={this.getSettings}>RESET</OutlinedButton>
                <ContainedButton onClick={this.updateSettings}>SAVE CHANGES</ContainedButton>
              </div>
            </>
          )}

          {this.state.selectedTab === 1 && (
            <div className="cf_settings__content">
              <div className="cf_settings__content__header">
                <h2>
                  {configJSON.tabs.find((tab: { id: number; title: string; }) => tab.id === this.state.selectedTab).title}
                </h2>
              </div>

              <div className="cf_settings__print">
                <h3>Automatic Printing</h3>

                <div className="cf_settings__print__control bordered">
                  <p>
                    Enable automatic printing on Instore Orders<br />
                    <span className="cf_settings__print__caption">(includes instore and telephone orders)</span>
                  </p>

                  <WingosSwitch
                    checked={this.state.automatic_printing_instore_order}
                    onChange={(_event, checked) => this.handleSwitchChange("automatic_printing_instore_order", checked)}
                  />
                </div>

                <div className="cf_settings__print__control bordered">
                  <p>
                    Enable automatic printing on Online Orders
                  </p>

                  <WingosSwitch
                    checked={this.state.automatic_printing_online_order}
                    onChange={(_event, checked) => this.handleSwitchChange("automatic_printing_online_order", checked)}
                  />
                </div>
              </div>

              <div className="cf_settings__print">
                <h3>Double Printing</h3>

                <div className="cf_settings__print__control">
                  <p>
                    Enable double printing on all orders
                  </p>

                  <WingosSwitch
                    checked={this.state.double_printing_all_order}
                    onChange={(_event, checked) => this.handleSwitchChange("double_printing_all_order", checked)}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {this.renderModals()}
        {this.renderPopups()}
        {this.renderDrawer()}
      </DashboardWrapper>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
