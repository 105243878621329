import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { SpiceLevelTypes } from "../../ordermanagement/src/types";
import { SpiceLevelType } from "../../catalogue/src/MenuPageController";
import { dietType } from "../../catalogue/src/MenuData";
import { throttle } from "lodash";
import { CartDetails, CartItem, DealProduct, OrderDetails } from "./CfposPrductDescriptionController";
// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import React from "react";

export interface StoreHours{
  open: string;  
  close: string;
};

export interface AllergenData {
  id: number,
  name: string,
  created_at: string,
  updated_at: string,
  description: string
}

export interface RestaurantData{
  id: number;
  title: string;
  location: string;
  contact: string;
  open_time: string;
  close_time: string;
  postcode: string;
  created_at: string;
  updated_at: string;
  order_type: string[];
  tax_reg_no: string;
  estimated_delivery_time: number; 
  estimated_collection_time: number; 
  latitude: string; 
  longitude: string;
  radius: number;
  time_zone: string;
  time_format: string; 
  store_hours: {
    Friday: StoreHours;
    Monday: StoreHours;
    Sunday: StoreHours;
    Tuesday: StoreHours;
    Saturday: StoreHours;
    Thursday: StoreHours;
    Wednesday: StoreHours;
  };
  supports_delivery: boolean;
  supports_collection: boolean;
  accepts_card_payment: boolean;
  accepts_cash_payment: boolean;
  primary_phone: string; 
  secondary_phone: string; 
  country_code: string; 
  automatic_printing_instore_order: boolean;
  automatic_printing_online_order: boolean;
  double_printing_all_order: boolean;
  secondary_phone_country_code: string;
};

export interface SubSubCategory{
  id: number;
  sub_category_id: number;
  title: string; 
  description: string;
  created_at: string;
  updated_at: string;
  newly_launched: boolean; 
  serves: string; 
  spice_level:string;
  product_type: string;
  enable: boolean;
};

export interface CustomizationData {
  id: number, food_category_id: number, title: string, with_price: boolean
}

export interface ItemCustomization{
  data: {
    id: string;
    type: string;
    attributes: {
      id: number,
      title:string;
      priority: number,
      customizations: CustomizationData[]
    }
  }
}

export interface CatalogueDataUnSorted {
  id: string;
  type: string;
  attributes: CatalogueDataUnSortedAttributes
}

export interface CatalogueDataUnSortedAttributes{
  restaurant: RestaurantData;
  sub_sub_category: SubSubCategory;
  images: {
    content_type
    : string;
    file_name
    : string;
    url
    : string
  }
  availability: string;
  stock_qty: number;
  on_its_own_price: number;
  two_reg_sides_price: number | null;
  allergens: AllergenData[];
  sides_details: { id: number, title: string } | null;
  sub_category: string;
  item_customization: ItemCustomization;
  priority: number;
  deal_products:DealProduct[] | null
  food_category:string;
}

export interface CatalogueDataSorted{
  sub_category: string;
  sub_category_id: number;
  priority:number;
  data: CatalogueDataUnSorted[];
}
type Timer = ReturnType<typeof setTimeout>;
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  locationSearch?:any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  menuDealsOption:number;
  categoryOption:number;
  catalogueCategoryData:CatalogueDataSorted[];
  filterPopupOpen:boolean;
  allergenList: string[];
  spiceLevelList: SpiceLevelTypes[];
  dietType: SpiceLevelType[];
  dietSelectedPopup:string | null;
  allergenSelected: string[];
  allergenSelectedPopup: string[];
  spiceLevelSelected: string | null;
  spiceLevelSelectedPopup: string | null;
  dietSelected: string | null;
  dietSelectedName: string;
  spiceLevelSelecteName: string;
  searchParameter:string;
  searchValue:string;
  loading:boolean;
  singleCatalogueData:CatalogueDataUnSorted | null;
  orderDescriptionPopupOpen:boolean;
  cartDetails:CartDetails;
  orderDetails:OrderDetails|null;
  emptyCartPopup:boolean;
  cartItemId:number;
  exclude_allergen: string;
  exclude_allergen_word_count: number;
  exclude_allergen_word_count_error: string;
  allergenPopup:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CfposordermanagementfeController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCatalogueItemApiCallId:string=""
  getSpiceLevelApiCallId:string=""
  getAllergensApiCallId:string=""
  getAllCartItemsApiCallId:string;
  categoryRefs:(HTMLDivElement | null)[]
  catalogueItemContainerRef: HTMLDivElement | null
  token:string;
  debounceTimer: Timer| null = null;
  updateCartItemCount:string;
  deleteCartItem:string;
  cancelOrderApiCallId:string;
  addAllergensApiCallId:string
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.categoryRefs=[]
    this.catalogueItemContainerRef = null
    this.getAllCartItemsApiCallId=""
    this.token=""
    this.debounceTimer= null
    this.updateCartItemCount=""
    this.deleteCartItem=""
    this.cancelOrderApiCallId=""
    this.addAllergensApiCallId=""
    // Customizable Area End

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      menuDealsOption:1,
      categoryOption:0,
      catalogueCategoryData:[],
      filterPopupOpen:false,
      allergenList: [],
      spiceLevelList:[],
      dietType:dietType,
      dietSelectedPopup: null,
      allergenSelected:[],
      allergenSelectedPopup:[],
      spiceLevelSelected:  null,
      spiceLevelSelectedPopup:  null,
      dietSelected: null,
      dietSelectedName: "",
      spiceLevelSelecteName: "",
      searchParameter:"",
      searchValue:"",
      loading:false,
      singleCatalogueData:null,
      orderDescriptionPopupOpen:false,
      cartDetails:{
        data:[],
        total_price:"0",
      },
      orderDetails:null,
      emptyCartPopup:false,
      cartItemId:0,
      exclude_allergen: "",
      exclude_allergen_word_count: 0,
      exclude_allergen_word_count_error: "",
      allergenPopup:false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if(message.getData(getName(MessageEnum.NavigationPayLoadMessage))){
      let navigationPayload = message.getData(getName(MessageEnum.NavigationPayLoadMessage));
      this.props.navigation.setParam("orderType",navigationPayload.order_type,"custId")
      this.props.navigation.setParam("custId",navigationPayload.cust_id)
      this.getAllCartData(navigationPayload.cust_id)
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData( getName(MessageEnum.RestAPIResponceDataMessage) );
      let responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if(apiRequestCallId === this.getCatalogueItemApiCallId){
        this.setCatalogueData(responseJson)
      }if(apiRequestCallId === this.getSpiceLevelApiCallId){
        this.setSpiceLevel(responseJson)
      }if(apiRequestCallId === this.getAllergensApiCallId){
        this.setAllergens(responseJson)
      }if(apiRequestCallId === this.getAllCartItemsApiCallId){
        this.setCartItemData(responseJson)
      }if(apiRequestCallId === this.updateCartItemCount){
        this.setItemCountUpdate(responseJson)
      } if(apiRequestCallId === this.deleteCartItem){
        this.updateDeletedCart(responseJson)
      } if(apiRequestCallId === this.cancelOrderApiCallId){
        this.setCancelOrder(responseJson)
      } if(apiRequestCallId === this.addAllergensApiCallId){
        this.updateAllergeData(responseJson)
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let message = new Message(getName(MessageEnum.AccoutLoginSuccess));
    message.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(message);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  componentDidMount=async()=>{
    this.token = localStorage.getItem("authToken") ||""
    const params = new URLSearchParams(this.props.locationSearch);
    const account_id = params.get('custId');
    if(this.token && account_id){
      this.getAllCartData(account_id)
    }
    this.getCatalogueItems()
      this.getAllergens()
      this.getSpiceLevel()
    if (this.catalogueItemContainerRef) {
      this.catalogueItemContainerRef.addEventListener('scroll', throttle(this.handleScroll, 100));
    }
  }
  componentDidUpdate=async(prevProps: Readonly<Props>, prevState: Readonly<S>)=> {
    const {spiceLevelSelected,allergenSelected,dietSelected,searchParameter} = this.state
    if (
      prevState.spiceLevelSelected!== spiceLevelSelected ||
      prevState.allergenSelected !== allergenSelected ||
      prevState.dietSelected !== dietSelected ||
      prevState.searchParameter !== searchParameter ||
      prevState.menuDealsOption !== this.state.menuDealsOption
    ) {
      this.getCatalogueItems()
    }
  }
  handleMenuDealOpionChange=(event: React.ChangeEvent<{}>, newValue: number)=>{
    this.setState({menuDealsOption:newValue})
  }

  handleCategoryOptionChange=(event:React.ChangeEvent<{}>, newValue:number)=>{
    this.setState({categoryOption:newValue})
  }

  getCategoryOptions =()=>{
    const categoryList = this.state.catalogueCategoryData.length>0 ?
    ["All",...this.state.catalogueCategoryData.map(value=>
     ( value.sub_category)
    )] : []
   return categoryList
  }
  
  getExpectedCatalogueData = (input: CatalogueDataUnSorted[]): CatalogueDataSorted[] => {
    const result = Object.values(
      input.reduce((acc: { [key: number]: CatalogueDataSorted }, item: CatalogueDataUnSorted) => {
        const subSubCategory = item.attributes.sub_sub_category
        if (!acc[subSubCategory.sub_category_id]) {
          acc[subSubCategory.sub_category_id] = {
            sub_category: item.attributes.sub_category,
            sub_category_id: subSubCategory.sub_category_id,
            priority: item.attributes.priority,
            data: [],
          };
        }
        acc[subSubCategory.sub_category_id].data.push(item);
        return acc;
      }, {})
    ).sort((a, b) => a.priority - b.priority);
    return result
  }

  setCatalogueData=(responseJson:{data?:CatalogueDataUnSorted[],errors?:any})=>{
    if(responseJson.data){
      this.setState({catalogueCategoryData:this.getExpectedCatalogueData(responseJson.data),loading:false,categoryOption:0})
    }
    if(responseJson.errors){
        if(responseJson.errors[0].includes("Token")){
          this.showAlert("token","token")
          this.setState({loading:false})
        }
    }
  }

  getCatalogueItems =() =>{
    const token = localStorage.getItem("authToken")
    const restaurant_id = localStorage.getItem("restaurantId")
    const diet = this.state.dietSelected
    const spiceLevel =this.state.spiceLevelSelected
    const allergen:string[] = this.state.allergenSelected
    const search =this.state.searchParameter
    if(token){
      this.setState({loading:true})
      const header = {
        "Content-Type": configJSON.validationApiContentType,
        token: token,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getCatalogueItemApiCallId = requestMessage.messageId;

      const params= {
        product_type: diet || undefined,
        spice_level: spiceLevel || undefined,
        allergens: allergen.length > 0 ? allergen : undefined,
        query: search || undefined,
        restaurant_id: restaurant_id || undefined
      }

      const queryString = this.createQueryString(params)
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${this.state.menuDealsOption?configJSON.menuDataEndPoint:configJSON.dealsDataEndPoint}?${queryString}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        header
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    else{
      this.showAlert("token","token")
    }
  }

  createQueryString = (params: Record<string, any>): string => {
    return Object.entries(params)
      .filter(([_, value]) => value !== undefined)
      .map(([key, value]) => 
        Array.isArray(value)
          ? `${key}=${value.join(',')}` 
          : `${key}=${encodeURIComponent(value)}`
      )
      .join('&');
  };

  handleFilterPopupClose=()=>{
    this.setState({filterPopupOpen:false})
  }
  handleFilterPopupOpen=()=>{
    this.setState({filterPopupOpen:true})
  }
  getSpiceLevel=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSpiceLevelApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSpiceLevelApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getAllergens=()=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllergensApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allergensListApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleSpiceChange=(event:React.ChangeEvent<HTMLInputElement>):void=>{
    this.setState({spiceLevelSelectedPopup: event.target.value, spiceLevelSelecteName: event.target.name})
  }

  clearSpiceChange=()=>{
    this.setState({spiceLevelSelected: '',spiceLevelSelectedPopup:null})
  }

  handleDietChange=(event:React.ChangeEvent<HTMLInputElement>):void=>{
    this.setState({ dietSelectedPopup: event.target.value ,dietSelectedName: event.target.name})
  }

  clearDietChange= ()=>{
    this.setState({dietSelected: '', dietSelectedPopup:null})
  }

  handleAllergenChange=(value:string):void=>{
    const isAlreadySelected = this.state.allergenSelectedPopup.includes(value);

    if(isAlreadySelected){
      const updatedAllergens = this.state.allergenSelectedPopup.filter(item => item !== value);
      this.setState({ allergenSelectedPopup: updatedAllergens });
    }else{
      this.setState({ allergenSelectedPopup: [...this.state.allergenSelectedPopup, value] });
    }
  }
  handleApplyFilters=():void=>{
    this.setState({filterPopupOpen:false,dietSelected: this.state.dietSelectedPopup, spiceLevelSelected: this.state.spiceLevelSelectedPopup,
    allergenSelected: this.state.allergenSelectedPopup})

  }
  handleClearFilters=():void=>{
    this.setState({spiceLevelSelected:null,allergenSelected:[], dietSelectedPopup:null, dietSelected:'',filterPopupOpen:false, spiceLevelSelectedPopup:null})
  }

  setAllergens=(responseJson:any)=>{
    if(responseJson.data){
      this.setState({allergenList:[...responseJson.data.map((value:any)=>(value.name))]})
    }
  }
  setSpiceLevel=(responseJson:any)=>{
    if(responseJson.data){
      this.setState({spiceLevelList:responseJson.data})
    }
  }

  handleSearchChange=(event:React.ChangeEvent<HTMLInputElement>):void=>{
    this.setState({searchValue: event.target.value})
  }
  getSearchResults=(event:React.KeyboardEvent<HTMLInputElement>)=>{
    if(event.key === "Enter"){
      this.setState({spiceLevelSelected:'', allergenSelected:[], dietSelected:'', searchParameter:this.state.searchValue})
    }
    if(event.key === "Backspace" && this.state.searchValue===""){
      this.setState({searchParameter:""})
    }
  }
  handleSearchClear=()=>{
    this.setState({searchParameter:"",searchValue:""})
  }

  scrollToSection = (value: number) => {
    let index:number
    if(value===0){
      index = 1
    }else{
      index = value
    }
    const categoryRef = this.categoryRefs[index];
    if (categoryRef && this.catalogueItemContainerRef) {
      this.catalogueItemContainerRef.scrollTo({
        top: categoryRef.offsetTop - this.catalogueItemContainerRef.offsetTop,
        behavior: "smooth",
      });
    }
  };

  componentWillUnmount=async()=>{
    if (this.catalogueItemContainerRef) {
      this.catalogueItemContainerRef.removeEventListener('scroll', this.handleScroll);
    }
  }
  
  handleScroll = () => {
    if (!this.catalogueItemContainerRef || this.categoryRefs.length === 0) {
      return;
    }
  
    const containerTop = this.catalogueItemContainerRef.offsetTop;
    let closestIndex = 0;
    let minDifference = Infinity;
  
    this.categoryRefs.forEach((ref, index) => {
      if (ref && this.catalogueItemContainerRef) {
        const offsetDifference = Math.abs(
          ref.offsetTop - containerTop - this.catalogueItemContainerRef.scrollTop
        );
        if (offsetDifference < minDifference) {
          minDifference = offsetDifference;
          closestIndex = index;
        }
      }
    });
    if (this.state.categoryOption !== closestIndex) {
      this.setState({ categoryOption: closestIndex });
    }
  };

  setIndividualItemData=(singleCatalogueData:CatalogueDataUnSorted)=>{
      this.setState({singleCatalogueData,orderDescriptionPopupOpen:true})
  }

  closeDescriptionPopup=()=>{
    this.setState({orderDescriptionPopupOpen:false})
  }

  getAllCartData=(account_id:string)=>{
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token:this.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllCartItemsApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getAllCartItemsApiEndPoint}${account_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setCartItemData=(responseJson:{cart_details?:CartDetails,order_details?:OrderDetails,error?:any})=>{
    if(responseJson.cart_details && responseJson.order_details){
      this.setState({orderDetails:responseJson.order_details,cartDetails:responseJson.cart_details})
    }
  }

  buttonDisabled=()=>{
    return !this.state.cartDetails
  }

  getCartExtraDetails=(cartDetail:CartItem)=>{
    const { sides, drinks, nibbles, toppings,choose_your_type } = cartDetail.attributes;
              const items = [
                ...sides.map((value) => value.name),
                ...drinks.map((value) => value.name),
                ...nibbles.map((value) => value.name),
                ...toppings.map((value)=>value.name)
              ];
              const formattedItems = items.filter(item => item).join(', ');
              const showItems = choose_your_type +" | " + formattedItems;
              return showItems
  }

  updateCountItem=(id:any,quantity:number)=> {
    const headers = { 
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
  
    const httpBody = {
      account_id: this.state.orderDetails && this.state.orderDetails.data.attributes.account_id,
      cart: { 
        quantity
      }
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.updateCartItemCount = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateItemsApiEndPoint + `${id}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  itemInTimeout(cartItem: CartItem) {
    const { id, quantity } = cartItem.attributes;
  
    this.updateCountItem(id, quantity);
  }
  
  debounce(func: (...args: any[]) => void, delay: number) {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(func, delay);
  }


  
  incDecItemCount(
    cartData: CartDetails,
    idx: number,
    action: string
  ) {
    const item = cartData.data[idx];
    const quantityChange = action === "addItem" ? 1 : -1;
  
    item.attributes.quantity += quantityChange;
  
    this.setState({ cartDetails: cartData });
  
    this.debounce(() => this.itemInTimeout(item), 50);
  }
  


  addRemoveCartItems(item: CartItem, action: string | null) {
    if (!action) return;
  
    const cartData = { ...this.state.cartDetails };
    const idx = cartData.data.findIndex(
      (cartItem) =>
        cartItem.id === item.id
    );
  
    const cartItem = cartData.data[idx];
  
    if (action === "addItem") {
      this.incDecItemCount(cartData, idx, action);
    } else if (action === "removeItem") {
      if (cartItem.attributes.quantity === 1) {
        this.setState({
          emptyCartPopup:true,
          cartItemId:cartItem.attributes.id
        });
      } else {
        this.incDecItemCount(cartData, idx, action);
      }
    }
  }

  setItemCountUpdate=(responseJson:any)=>{
      if(responseJson.errors){
          this.showAlert('token','token')
      } else{
        this.setState((prevState) => ({
          cartDetails: {
            ...prevState.cartDetails,total_price:responseJson.total_price
          }
        }))
      }
  }

  deleteCartClicked=(id:number)=>{
      this.setState({cartItemId:id,emptyCartPopup:true})
  }

  deleteCart=()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      account_id:this.state.orderDetails && this.state.orderDetails.data.attributes.account_id
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteCartItem = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateItemsApiEndPoint+`${this.state.cartItemId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  closeEmptyCart=()=>{
    this.setState({emptyCartPopup:false,cartItemId:0})
  }

  updateDeletedCart=(responseJson:any)=>{
    if(responseJson.message && responseJson.message === "cart item deleted succefully"){
     this.setState((prevState) => ({
      cartDetails: {
        ...prevState.cartDetails,
        data: prevState.cartDetails.data.filter(item => item.attributes.id !== this.state.cartItemId),
      }, emptyCartPopup:false
    }))
    }
  }
  updateAllergeData=(responseJson:{data?:CartItem[]})=>{
      if(responseJson.data){
        this.setState({cartDetails:{...this.state.cartDetails, data: responseJson.data},allergenPopup:false})
      }
  }
  updateCartItemsOnAdding=(cartDetails:CartDetails,orderDetails:OrderDetails)=>{
    this.setState({cartDetails,orderDetails})
    this.closeDescriptionPopup()
  }
  cancelOrderApiCall=()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      account_id:this.state.orderDetails && this.state.orderDetails.data.attributes.account_id
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.cancelOrderApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.cancelOrderApiEndPoint+`${this.state.orderDetails && this.state.orderDetails.data.id}/cancel_order`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  setCancelOrder=(responseJson:{message?:string})=>{
    if(responseJson.message && responseJson.message === "Order cancelled successfully"){
      this.props.navigation.navigate('PosOrderCreation')
    }
  }
  excludeAllergenChange= (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const exclude_allergen = event.target.value
      const exclude_allergen_word_count = event.target.value.length
      if (exclude_allergen_word_count > 20) {
        const exclude_allergen_word_count_error = "Cannot use more than 20 characters."
        this.setState({ exclude_allergen_word_count_error })
      } else {
        this.setState({ exclude_allergen_word_count_error: "" })
      }
      this.setState({ exclude_allergen, exclude_allergen_word_count })
  }
  openAllergenPopup=(id:number)=>{
    this.setState({allergenPopup:true,cartItemId:id})
  }
  closeAllergenPopup=()=>{
    this.setState({allergenPopup:false,cartItemId:0})
  }

  addAllergens=()=>{
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": this.token
    };
    const body = {
      account_id:this.state.orderDetails && this.state.orderDetails.data.attributes.account_id,
      cart:{
        allergen_excluded:this.state.exclude_allergen
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAllergensApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.updateItemsApiEndPoint+`${this.state.cartItemId}`
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );
  
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
