import React from "react";
import moment from "moment-timezone";
import { DateLocalizer, Event, Navigate, ViewProps } from "react-big-calendar";
import { getEventColor, getSessionStorage } from "../../../../utils";
import "./Timeline.css";
import { EventComponent } from "../../components/Event";

const TimelineView = ({ date, localizer, events, components }: ViewProps) => {
  const [members, setMembers] = React.useState<React.ReactNode[]>([]);
  const [shifts, setShifts] = React.useState<Event[]>([]);
  const [weekDates, setWeekDates] = React.useState<string[]>([]);

  const start = React.useMemo(() => localizer.startOf(date, "week", 0), [date, localizer]);
  const end = React.useMemo(() => localizer.add(start, 6, "day"), [start, localizer]);

  const { data } = components;
  const { selectedStaff, selectedRole, activeView, openShiftPopup } = data;

  // Generate week dates (moved out of useEffect to avoid re-creating on every render)
  const generateWeekDates = React.useCallback(() => {
    const startOfWeek = moment(date).startOf("week");
    const endOfWeek = moment(date).endOf("week");
    const dates: string[] = [];

    let current = startOfWeek.clone();
    while (current <= endOfWeek) {
      dates.push(current.format("MMM DD"));
      current.add(1, "day");
    }
    return dates;
  }, [date]);

  // Update week dates when date changes
  React.useEffect(() => {
    const dates = generateWeekDates();
    if (dates.join(",") !== weekDates.join(",")) {
      setWeekDates(dates);
    }
  }, [generateWeekDates, weekDates]);

  // Filter events and update members
  React.useEffect(() => {
    const filteredEvents = filterEventsByDate(events ?? [], start, end);
    const uniqueMembers = Array.from(new Set(filteredEvents.map((event) => event.title)));

    // Only update state if values have changed
    if (
      filteredEvents.length !== shifts.length ||
      !filteredEvents.every((e, i) => e === shifts[i])
    ) {
      setShifts(filteredEvents);
    }

    if (uniqueMembers.join(",") !== members.join(",")) {
      setMembers(uniqueMembers);
    }
  }, [events, start, end, shifts, members]);

  return (
    <div className="timeline__wrapper">
      {/* Header */}
      <div className="timeline__header timeline__row">
        <div className="timeline__header__title timeline__members">
          <p>Staff Members</p>
        </div>
        <div className="timeline__header__days">
          {weekDates.map((date) => (
            <div key={date} className="timeline__header__day">
              <p>{date}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Members and Events */}
      <div className="timeline__body">
        {members.map((member, idx) => (
          <div key={idx} className="timeline__row">
            <div className="timeline__member">
              <p>{member}</p>
            </div>
            <div className="timeline__events">
              {shifts
                .filter((shift) => shift.title === member)
                .map((shift, i) => {
                  const shiftStart = moment(shift.start);
                  const shiftEnd = moment(shift.end);
                  const dayOffset = shiftStart.diff(start, "days");
                  const span = shiftEnd.diff(shiftStart, "days") + 1;

                  return (
                    <EventComponent
                      key={i}
                      selectedStaff={selectedStaff}
                      selectedRole={selectedRole}
                      activeView={activeView}
                      openShiftPopup={openShiftPopup}
                      event={shift}
                      style={{
                        gridColumn: `${dayOffset + 1} / span ${span}`,
                      }}
                    />
                  );
                })}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

TimelineView.range = (date: Date, { localizer }: { localizer: DateLocalizer }) => {
  const start = localizer.startOf(date, "week", 0); // Start of the week (Monday)
  const end = localizer.endOf(date, "week", 0);     // End of the week (Sunday)

  const range = [];
  let current = start;

  while (localizer.lte(current, end, "day")) {
    range.push(current);
    current = localizer.add(current, 1, "day"); // Increment by 1 day
  }

  return range;
};

TimelineView.navigate = (
    date: Date,
    action: any,
    { localizer }: { localizer: DateLocalizer }
) => {
    if (action instanceof Date) return action;

    switch(action) {
        case Navigate.NEXT:
            return localizer.add(date, 1, "week");
        case Navigate.PREVIOUS:
            return localizer.add(date, -1, "week");
        default:
            return date;
    }
};

TimelineView.title = (
  date: Date,
  { localizer }: { localizer: DateLocalizer }
) => {
  const start = localizer.startOf(date, "week", 0);
  const end = localizer.add(start, 6, "day");

  const month = moment(start).format("MMMM")
  const from = moment(start).format("DD");
  const to = moment(end).format("DD");

  return `${month} ${from} - ${to}`;
};

const filterEventsByDate = (events: Event[], startDate: string, endDate: string): Event[] => {

  const start = moment(startDate).toDate();
  const end = moment(endDate).toDate();

  // Filter events that fall within the date range
  return events.filter(event => {
    const eventStart = event.start;

    if (eventStart) return eventStart >= start && eventStart <= end;
  });
}

export default TimelineView;
